
import generalConfig from './general-config'
import euroParImg from './assets/par-equal-euro.png'

// data-trk ignoreFile
const translations = {
  //----------------------------------------
  // ENGLISH
  //----------------------------------------
  en: {
    //----------------------------------------
    // GENERIC
    //----------------------------------------
    numbers: {
      1: `one`,
      2: `two`,
      3: `three`,
      4: `four`,
      5: `five`,
      6: `six`,
      7: `seven`,
      8: `height`,
      9: `nine`,
      10: `ten`,
    },
    yearShort: `Y`,
    yearsShort: `Yrs`,
    upTo: `up to`,
    APY: `APY`,
    hello: `Hello`,
    year: `year`,
    years: `years`,
    frequency: `Frequency`,
    frequencyPeriod: {
      monthly: `Monthly`,
      weekly: `Weekly`,
      daily: `Daily`,
      yearly: `Yearly`,
    },
    back: `Back`,
    start: `Start`,
    iAgree: `I agree`,
    myAccountName: `My {{accountName}} account`,
    createNew: `Create new`,
    myWallets: `My wallets`,
    transactionHistory: `Transaction history`,
    viewAll: `View all`,
    popularCryptocurrencies: `Popular cryptocurrencies`,
    cryptoVaultTitle: `Crypto`,
    savingVaultTitle: `Saving Vault`,
    other: `Other`,
    enter: `ENTER`,
    done: `Done`,
    from: `From`,
    to: `To`,
    account: `Account`,
    balance: `Balance`,
    almostThere: `Almost there`,
    transfer: `Transfer`,
    transactionTypes: {
      withdraw: `Withdraw`,
      recurringTransfer: `RecurringTransfer`,
      deposit: `Deposit`,
      buy: `Buy`,
      sell: `Sell`,
      earn: `Daily interests`,
    },
    cancel: `Cancel`,
    confirm: `Confirm`,
    amount: `Amount`,
    congratulation: `Congratulation`,
    deposit: `Deposit`,
    withdraw: `Withdraw`,
    progress: `Progress`,
    transactions: `Transactions`,
    builder: `Builder`,
    last24hEarnings: `Last 24H earnings`,
    wealthHub: `Wealth Hub`,
    week: `Week`,
    month: `Month`,
    all: `All`,
    password: `Password`,
    installAppBtn: `INSTALL APP`,
    installAppDialogContent: `Would you like to install AKT.IO prototype app ?`,
    workInProgress: `Hey, this feature is not available currently but we are doing our best to release it soon :)`,
    noTransactionForTheMoment: `Oops! No transactions have been made actually :)`,
    //----------------------------------------
    // DASHBOARD
    //----------------------------------------
    vaultInfoCta: {
      title: `Earn on your savings`,
      link: `Discover our high interest Vaults`,
      description: html(`Join akt.io adventure and earn up to <b>${generalConfig.vaults.percentPremium}%+ APY</b> (Annual Percentage Yield)! Build your Saving Vault and watch your money grow without lifting a finger`),
    },
    // LOGIN PAGE
    enterYourDetails: `Enter your details`,
    gender: `Gender`,
    female: `Female`,
    male: `Male`,
    birthDate: `Birth date (DD/MM/YYYY)`,
    //----------------------------------------
    // NEW VAULT FEATURE
    //----------------------------------------
    // NEW VAULT
    buildYourVault: `Build your vault`,
    newVaultDefaultTitle: `My Smart Vault`,
    vaultTags: {
      travel: `Travel`,
      gift: `Gift`,
      house: `House`,
      hobbies: `Hobbies`,
      car: `Car`,
      renovations: `Renovations`,
      retirement: `Retirement`,
      saving: `Saving`,
      studies: `Studies`,
    },
    vaultNamesSuggestions: {
      travel: `My Travel Vault`,
      gift: `My Gift Vault`,
      house: `My House Vault`,
      hobbies: `My Hobbies Vault`,
      car: `My Car Vault`,
      renovations: `My Renovations Vault`,
      retirement: `My Retirement Vault`,
      saving: `My Saving Vault`,
      studies: `My Studies Vault`,
    },
    changeCoverImage: `Change cover image`,
    personalizedVaultAnimation: `Personalized Vault animation`,
    browseOurSelection: `Browse our selection`,

    vaultMainSectionDescriptionTitle: `You are now building your Saving Vault.`,
    vaultMainSectionDescription: `Add as many options as you want now or do it after launching`,
    dailyInterest: `Daily interest`,
    firstDeposit: `First deposit`,
    savingGoal: `Saving goal`,
    recurringTransfer: `Recurring transfer`,
    newVaultValidateBtnText: `Launch my vault`,
    newVaultValidateBtnUnderDescription: `You can edit your settings later`,
    vaultCreationConfirmDrawerMessage: html(`<span class='text-primary'>Your Vault is about to be launched.</span><br/>Are you happy with your settings before you leave the builder?`),
    vaultDrawerConfirmBtn: `Yes, launch my Vault`,
    vaultDrawerCancelBtn: `Not now, keep on editing`,
    // NEW VAULT DRAWER
    createNewWallet: `Create new Wallet`,
    chooseAccountType: `Choose the type of account to create`,
    NewSavingVaultPageTitle: `Saving Vault`,
    NewSavingVaultPageDescription: `Our AKTIO Vaults allows you to make your savings grow up to ${generalConfig.vaults.percentPremium}%+ APY without lifting a finger`,
    newCryptoVaultTitle: `Crypto`,
    newCryptoVaultDescription: `Browse trough 100+ crypto assets and trade them through our revolutionary exchange engine: The WealthHub`,
    // NEW VAULT CONFIG SCREEN
    newVaultConfigTitle: `Design your Vault`,
    vaultName: `Vault name`,
    vaultColor: `Vault color`,
    vaultBackgroundColor: `Background`,
    yourVaultHasBeenCreated: `Your Vault has been created`,
    // DAILY INTEREST CONFIG PAGE
    dailyInterestPageTitle: `Make your savings grow up`,
    howItWorks: `How it works`,
    learnMore: `Learn more`,
    wantToKnowHowMuchYouCanEarn: `Want to know how much you can earn ?`,
    simulateYourEarnings: `Simulate your earnings`,
    activateSmartVault: `Activate smart vault`,
    dailyInterestUpTo: `Daily interest up to ${generalConfig.vaults.percentSmart}% APY`,
    dailyInterestUpToBoosted: `Daily interest up to ${generalConfig.vaults.percentPremium}% APY`,
    dontMissOut: `Don't miss out`,
    upgradeToPremiumVault: `Upgrade to premium vault`,
    upgradeToPremiumVaultSuccess: `Upgrade to premium vault successful`,
    premiumVaultActive: `Premium vault active`,
    earnUpToBoosted: `Earn up to ${generalConfig.vaults.percentPremium}% APY*`,
    vaultBoostDescription: html(`Add 150€ to your AKTIO wallet for 12 months and start generating real returns of up to ${generalConfig.vaults.percentPremium}% APY.<br/>Activate daily interest first to access Premium Vault`),
    doItLater: `Do it later`,
    smartVaultDrawerDisclaimer: html(`In order to earn daily interest in your vault, the money you will deposit in your vault has to be exchanged from EURO into EURO PAR.<br/><br/>This EURO stablecoin will be deposited on decentralized stable coin issuance protocol. EURO PAR is secured at all times by asset-backed portfolios of overcollateralized loans and start earning interest after 24hrs.<br/><br/>Every investment involves a certain level of risk. We recommend that you don't invest more than 10% of your total wealth`),
    smartVaultActivated: `Smart Vault activated`,
    premiumVaultActivation: `Premium Vault activation`,
    premiumVaultActivated: `Premium Vault activated`,
    premiumVaultTransferText: html(`You're one step away from upgrading to Premium Vault and earn up to <b>${generalConfig.vaults.percentPremium}% APY</b> with daily payouts.<br /><br />Join the adventure by investing 150€ of AKTIO and setting it aside for 12 months.`),
    annualInterestPaidDaily: `annual interest paid out daily`,
    // DAILY INTEREST LEARN MORE
    dailyInterestLearnMore: {
      pageTitle: `How it works`,
      pageSubtitle: `Save money today, earn daily interest and build yourself the future you deserve`,
      step1Title: `Build a Vault that's perfect for your plans`,
      step1Description: `Design your Vault, set saving goals, and add money with one tap. Make changes whenever you want. Take full advantage of the different building options`,
      step2Title: `Watch the magic happen`,
      step2Description: html(`We make your money grow. Your Vault earns you daily interest:<ul><li>up to 3% with one tap to activate daily interest</li><li>up to ${generalConfig.vaults.percentPremium}% if you add 150€ to your AKTIO wallet for one year</li></ul>`),
      step3Title: `Enjoy your growing wealth`,
      step3Description: `Sit back, relax and watch your money grow. Achieve your saving goals. Access your money whenever you want`,
      euroParEquality: `1 EURO PAR = 1 EURO`,
      section2Title: `Earn daily interest on EURO PAR`,
      section2Description: html(`Through a decentralized stable coin issuance protocol, we provide a safe way to earn in a new stable token pegged to the Euro = the EURO PAR, the world first decentralized EURO stablecoin.<br/><br/>When depositing money in your smart vault, you will be invited to deposit in EURO PAR with a guaranteed 1-to-1 conversion rate to EURO (no fees).<br/><br/>You earn up to ${generalConfig.vaults.percentPremium}% annual interest on your EURO PAR which is secured at all times by asset-backed portfolios of overcollateralized loans through a decentralized stable coin issuance protocol.<br/><br/>Upon a withdrawal request, the EUR equivalent of EURO PAR is automatically exchanged at the guaranteed 1-to-1 conversion rate and we send the EUR back to you.<br/><br/>You can withdraw anytime.`),
    },
    // SIMULATE EARNINGS
    simulateEarnings: `Simulate earnings`,
    simulateEarningPage: {
      youWillEarnIn: `You will earn in {{year}} {{yearStr}}`,
      youWillReach: `You will reach a total of`,
      youWillReach2: `with Premium Vault`,
      traditionalBanksSavings: `Traditional Banks savings`,
    },
    recurringTransferAmount: `Recurring transfer amount`,
    recurringTransferFrequency: `Recurring transfer frequency`,
    smartVault: `Smart Vault`,
    premiumVault: `Premium Vault`,
    last30dPremiumVault: `Last 30D Premium vault`,
    yieldRateDisclaimer: `This yield rate is calculated based on the average returns over the past 30 days. Yield rates are optimised on a daily basis. Past performance is no guarantee of future results.`,
    yieldRateDisclaimerEuroPar: `The yield is generated in Euro PAR, which is the world's first Euro stable coin. Upon a withdrawal request, the EUR equivalent of EURO PAR is automatically exchanged at the guaranteed 1-to-1 conversion rate and we send the EUR back to you`,
    getPremiumVault: `Get Premium Vault`,
    activateDailyEarnings: `Activate daily earnings`,
    activateDailyInterestFirst: `Activate daily interest first to access Premium Vault`,
    // FIRST DEPOSIT
    firstDepositPageTitle: `First deposit`,
    firstDepositSuccess: ({ amount }) => html(`Your transfer has been successful<br/><span class='text-primary'>${amount} added to your Vault</span>`),

    // SAVING GOAL
    savingGoalPageTitle: `Saving goal`,
    savingGoalHeader: `How do you want to save`,
    goalAmount: `Goal amount`,
    deadline: `Deadline`,
    // RECURRING TRANSFER
    recurringTransferPageHeader: `Don't think about it, let us do it`,
    recurringAdvice: `Regarding your goal settings, we advice you save {{saveAdvice}} {{period}} to reach your goal amount {{goalAmount}} on {{goalDate}}`,
    recurringTransferSuccess: ({ amount, frequency }) => html(`Your recurring transfer has been successfully set up for <span class='text-primary'>${amount} ${frequency}</span>`),
    // VAULT MAIN PAGE
    earnPeriodLabels: {
      // displayed in account description
      all: `All`,
      week: `7d`,
      month: `1m`,
      last24h: `24h`,
    },
    moneyDisclaimer: {
      // will appear on the question mark icon near total amount currency
      // will not appear if not set for a money
      par: html(`<b>1 EURO PAR = 1EURO<br/><br/><img src='${euroParImg}' /><br/><br/>Earn daily interest on EURO PAR</b><br/><br/>
      Through a decentralized stable coin issuance protocol, we provide a safe way to earn in a new stable token pegged to the Euro = the EURO PAR, the world first decentralized EURO stablecoin.<br/><br/>
      When depositing money in your Smart Vault, you will be invited to deposit in EURO PAR with a <b>guaranteed 1-to-1 conversion rate to EURO</b> (no fees).<br/><br/> 
       You earn up to 8% annual interest on your EURO PAR which is secured at all times by asset-backed portfolios of overcollateralized loans through a decentralized stable coin issuance protocol.<br/><br/>
       Upon a withdrawal request, the EUR equivalent of EURO PAR is automatically exchanged at the guaranteed 1-to-1 conversion rate and <b>we send the EUR back to you</b>. <br/><br/>
      You can withdraw anytime.`),

    },
    goalProgress: `Goal progress`,
    savedAmount: `Saved {{amount}}`,
    earnings: `Earnings`,
    youCouldHaveEarnToday: ({ amount }) => html(`You could have earn ${amount} today with Premium Vault`),
    liveInterestRate: `Live interest rate`,
    progressRanks: {
      0: `Cheer up! {{daysLeft}} days left`,
      1: `Cheer up! {{daysLeft}} days left`,
      2: `Another {{daysLeft}} to go`,
      3: `Another {{daysLeft}} to go`,
      4: `Yet {{daysLeft}} to go`,
      5: `Yet {{daysLeft}} to go`,
      6: `Yet {{daysLeft}} to go`,
      7: `Just {{daysLeft}} to go`,
      8: `Nearly! Just {{daysLeft}} to go`,
      9: `WOW! Just {{daysLeft}} to go`,
      10: `You did it!`,
    },
    lastDailyEarnings: `Last daily earnings`,
    closeMyAccount: `Close my account`,
    downloadAll: `Download all`,

  },
  //----------------------------------------
  // FRENCH
  //----------------------------------------
  fr: {
    enterYourDetails: `Entrez vos détails`,
    gender: `Sexe`,
    female: `Femme`,
    male: `Homme`,
    other: `Autre`,
    birthDate: `Date de naissance (DD/MM/YYYY)`,
    enter: `ENTRER`,
  }
}

//----------------------------------------
// HELPERS
//----------------------------------------
function html(str) {
  return <span dangerouslySetInnerHTML={{ __html: str }}></span>
}

export default translations