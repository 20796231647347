
import generalConfig from '../general-config'
import applicationError from 'src/00_core/application-error'

import { isset } from 'topkat-utils'

function convert(currencySourceId: string, currencyTargetId: string, amount: number) {
  if (!isset(generalConfig.exchangeRates[currencySourceId])) applicationError(`No exchangeRate set with ${currencySourceId}`)
  if (!isset(generalConfig.exchangeRates[currencySourceId][currencyTargetId])) applicationError(`No exchangeRate set for target ${currencyTargetId} and source ${currencySourceId}`)
  const rate = generalConfig.exchangeRates[currencySourceId][currencyTargetId]
  return amount * rate
}

const convertInUserCur = (ctx: any, amount: number): number => convert(ctx.ensureActiveAccount().currency.id, ctx.user.currency.id, amount)


export {
  convert,
  convertInUserCur,
}