import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import PageTitle from '../01_shared/PageTitle'
import Box from '@mui/material/Box'

import { isset } from 'topkat-utils'


type PagePropsType = { dataTrkName: string, title?: string, children?: any, previousUrl?: string, titleAbsolute?: boolean, backgroundColor?: string, [k: string]: any }

const Page = ({ dataTrkName, title, children, previousUrl, titleAbsolute = false, backgroundColor, ...props }: PagePropsType) => {
  const theme = useTheme()

  if (!isset(backgroundColor)) backgroundColor = theme.palette.background.default

  return <div data-trk={'p-' + dataTrkName} style={{ backgroundColor, minHeight: '100vh', zIndex: 100, position: 'absolute', width: '100%' }}>
    {title ? <PageTitle positionAbsolute={titleAbsolute} previousUrl={previousUrl}>{title}</PageTitle> : null}
    <Box {...props}> {children}</Box>
  </div>
}

export default Page