import * as t from '../01_shared/types'
import generalConfig from '../01_shared/general-config'

import { random } from 'topkat-utils'

const vaultDefaultConfig = ctx => {
  const randomColorIndex = random(0, generalConfig.vaults.colors.length - 1)
  return {
    ...ctx.createNewUserAccount('vault', true),
    name: ctx.t.newVaultDefaultTitle,
    color: generalConfig.vaults.colors[randomColorIndex],
    backgroundColor: generalConfig.vaults.backgroundColors[randomColorIndex],
    tags: ([] as string[]),
    isSmart: false,
    isPremium: false,
    hasSeenVideo: false,
    savingGoalActive: false,
    deadlineActive: false,
    savingGoal: 0,
    recurringTransferActive: false,
    recurringTransferFrequency: 'monthly' as t.RecurringTransferFrequency,
    recurringTransferAmount: 0,
    firstDepositAmount: 0,
    transactionsForFirstDeposit: ([] as t.AccountTransaction[]),
    revenuePerDay: ([] as number[]),
    totalAmount: 0,
    creationDate: new Date(),
  } as t.UserAccountType
}

export default vaultDefaultConfig