
const trackerConfig = {
  nbMsForRageClickCounterWait: 200,
  maxEventsInCacheBeforeForceSendingEventsViaApi: 30,
  swipeDetectionMinTravelDistancePx: 100,
  percentageItemInViewportToConsiderVisible: 0.7, // from 0 to 1
  analyseAsyncMs: 500, // time after render where app architecture analyse script is launched (async for perf)
  heatMapOpacity: 0.5,
}

export default trackerConfig