import tracker from 'src/Tracker/Tracker'
import { createBrowserHistory } from 'history'


import { noDuplicateFilter } from 'topkat-utils'

const history = createBrowserHistory()

type NavItem = { pathname: string, hash?: string }
let navHistory: NavItem[] = [{ pathname: '/' }]

window.onhashchange = function (e) {
  addToNavHistory(window.location)
}

history.listen(({ action, location }) => {
  if (action === 'PUSH') {
    if (previousNavItem().pathname === location.pathname) {
      if (navHistory.length > 1) navHistory.pop()
      tracker.sendEvent('previous', { url: location.pathname })
    } else {
      addToNavHistory(location)
      const maxNavItems = 8
      if (navHistory.length > maxNavItems) navHistory.splice(navHistory.length - maxNavItems, maxNavItems)
      tracker.sendEvent('navigate-to', { url: location.pathname })
    }
  } else if (action === 'POP') {
    if (location.pathname !== actualNavItem().pathname) navPrevious()
  }
  console.log(`NAVACTION`, action, JSON.stringify(location))
})

function navPrevious() {
  if (navHistory.length > 1) navHistory.pop()
  const prevUrl = navHistory[navHistory.length - 1] || { pathname: '/' }

  history.replace(prevUrl.pathname)
  tracker.sendEvent('previous', { url: prevUrl.pathname })

}

function addToNavHistory(location) {
  navHistory.push({ pathname: location.pathname, hash: location.hash })
  navHistory = noDuplicateFilter(navHistory.reverse(), (a, b) => a.pathname === b.pathname).reverse()
}

function previousNavItem(): NavItem {
  return navHistory[navHistory.length - 2] || { pathname: '/' }
}

function actualNavItem() {
  return navHistory[navHistory.length - 1] || { pathname: '/' }
}

function navigate(url: string) {
  history.push(url)
}

function previousUrl() {
  const prevNavItem = previousNavItem()
  return prevNavItem.pathname + (prevNavItem.hash || '')
}

export {
  previousNavItem,
  navPrevious,
  history,
  navigate,
  previousUrl,
}