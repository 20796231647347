import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import { appCtx } from '../01_shared/appContext'
import Page from '../01_shared/Page'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import Input from '@mui/material/Input'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import FullWidthButton from '../01_shared/FullWidthButton'
import { Link } from "react-router-dom"
import generalConfig from '../01_shared/general-config'
import * as t from '../01_shared/types'
import themeVarz from 'src/01_shared/theme/theme-varz'

import { miniTemplater, forI } from 'topkat-utils'

const last30DvaultPerf = 7.42
const heightDividerRatio = 0.3
const graphHeight = 200
const calculateCompoundInterests = false
const yearSelect = [1, 2, 5, 10]
const graphColors = {
  premium: ['#883095', '#F1D3F6'],
  smart: [themeVarz.primaryBlue, '#CDECFF'],
  traditional: ['#C0D4E3', '#F5F5F5'],
}
const percentages = {
  traditional: 0.005,
  smart: 0.04,
  premium: 0.08,
}
const dailyPercentages = {
  traditional: percentages.traditional / 365,
  smart: percentages.smart / 365,
  premium: percentages.premium / 365,
}

const defaultConfig: SimulationConfig = calculateTotalEarn({
  firstDeposit: 10000,
  recurringTransferAmount: 100,
  recurringTransferFrequency: 'monthly',
  savingDurationYear: 10,
  totalInterestPremium: 0,
  totalInterestSmart: 0,
  totalInterestTraditional: 0,
  totalRecurringEarn: 0,
  totalPremimuAmountAtTheEndOfPeriod: 0,
})
const dotStyle = (mainColor): any => ({ borderRadius: '50%', width: 15, height: 15, position: 'relative', backgroundColor: mainColor, display: 'inline-block', marginRight: 4, top: 3 })

const SimulateEarningPage = () => {
  const ctx = React.useContext(appCtx)

  const [simulationConfig, setSimulationConfig] = React.useState(defaultConfig)
  const updateSimulation = (prop, value) => setSimulationConfig(calculateTotalEarn({ ...simulationConfig, [prop]: value }))

  const yearStr = simulationConfig.savingDurationYear === 1 ? ctx.t.year : ctx.t.years
  const yearText = ctx.t.numbers[simulationConfig.savingDurationYear]
  const heightDivider = (((yearSelect[yearSelect.length - 1] / simulationConfig.savingDurationYear) - 1) * heightDividerRatio) + 1

  const handleChange = (name, forceType?: 'number') => (event) => {
    const formElement = event.currentTarget
    formElement.reportValidity()
    const val = event.target.value
    const cleanVal = forceType === 'number' ? parseFloat(val) : val
    updateSimulation(name, cleanVal)
  };

  return <Page dataTrkName='simulateEarning' title={ctx.t.simulateEarnings} sx={{ p: 2 }}>
    <Paper sx={{ p: 2 }}>
      <div style={{ textAlign: 'center', marginBottom: 24 }}>
        <Typography fontWeight='bold'>{miniTemplater(ctx.t.simulateEarningPage.youWillEarnIn, { year: yearText, yearStr })}</Typography>
        <Typography fontWeight='bold' fontSize={40}>{simulationConfig.totalInterestPremium.toFixed(2) + ctx.user.currency.symbol}</Typography>
        <Typography fontSize='small'>{ctx.t.simulateEarningPage.youWillReach} <Typography variant='primary'>{simulationConfig.totalPremimuAmountAtTheEndOfPeriod.toFixed(2) + ctx.user.currency.symbol}</Typography> {ctx.t.simulateEarningPage.youWillReach2}</Typography>
      </div>

      {/* FORM */}
      <Typography>{ctx.t.firstDeposit}</Typography>
      <Box component="form" sx={{ mb: 5 }}>
        <FormControl fullWidth sx={{ mt: 2, mb: 3 }}>
          <Input
            type='number'
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            value={simulationConfig.firstDeposit}
            onChange={handleChange('firstDeposit', 'number')}
            endAdornment={<InputAdornment position="end">{ctx.user.currency.short}</InputAdornment>}
            fullWidth
            style={{ fontWeight: 700, fontSize: 16 }}
          />
        </FormControl>

        <Typography>{ctx.t.recurringTransferAmount}</Typography>
        <FormControl fullWidth sx={{ mt: 2, mb: 3 }}>
          <Input
            type='number'
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            value={simulationConfig.recurringTransferAmount}
            onChange={handleChange('recurringTransferAmount', 'number')}
            endAdornment={<InputAdornment position="end">{ctx.user.currency.short}</InputAdornment>}
            fullWidth
            style={{ fontWeight: 700, fontSize: 16 }}
          />
        </FormControl>

        <Typography>{ctx.t.recurringTransferFrequency}</Typography>
        <FormControl fullWidth sx={{ mt: 2, mb: 3 }}>
          <TextField
            select
            value={simulationConfig.recurringTransferFrequency}
            onChange={handleChange('recurringTransferFrequency')}
            SelectProps={{ native: true, }}
            variant="standard"
            inputProps={{ style: { fontWeight: 700, fontSize: 16 } }}
          >
            {Object.keys(generalConfig.recurringTransferPeriods).map(freq => (
              <option key={freq} value={freq}>
                {ctx.t.frequencyPeriod[freq]}
              </option>
            ))}
          </TextField>
        </FormControl>
      </Box>
      {/* GRAPH */}
      <div style={{ position: 'relative', height: graphHeight }}>
        <GraphEarning amount={simulationConfig.totalInterestPremium} height={graphHeight / heightDivider} strokeColor={graphColors.premium[0]} gradientColor1={graphColors.premium[1]} priceTagZindex={3} />
        <GraphEarning amount={simulationConfig.totalInterestSmart} height={(graphHeight / percentages.premium / heightDivider) * percentages.smart} strokeColor={graphColors.smart[0]} gradientColor1={graphColors.smart[1]} priceTagZindex={2} />
        <GraphEarning amount={simulationConfig.totalInterestTraditional} height={(graphHeight / percentages.premium / heightDivider) * percentages.traditional} strokeColor={graphColors.traditional[0]} gradientColor1={graphColors.traditional[1]} priceTagZindex={1} />
      </div>
      <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: 8, marginBottom: 24 }}>
        {yearSelect.map(y => {
          return <Button data-trk={`a-chartSavingDurationPeriod-value:${y}`} key={y} size="small" variant={simulationConfig.savingDurationYear === y ? 'contained' : 'text'} onClick={() => updateSimulation('savingDurationYear', y)}>
            {y + (y > 1 ? ctx.t.yearsShort : ctx.t.yearShort)}
          </Button>
        })}
      </Box>
      <Box style={{ fontSize: 'small' }}>
        {['traditional', 'smart', 'premium'].map(accountType => {
          const isTraditional = accountType === 'traditional'
          return <div key={accountType}>
            <span style={dotStyle(graphColors[accountType][0])}></span>
            <span><b>{isTraditional ? ctx.t.simulateEarningPage.traditionalBanksSavings : ctx.t[accountType + 'Vault']} •</b> {ctx.t.upTo + ' '}<span style={{ fontWeight: isTraditional ? undefined : 700 }}>{(percentages[accountType] * 100) + '%' + (accountType === 'premium' ? '+' : '') + ' ' + ctx.t.APY}</span></span>
          </div>
        })}
        <Box my={3}>
          <Typography color='#883095' textAlign='center' fontWeight={700}>{ctx.t.last30dPremiumVault}: {last30DvaultPerf + `% ${ctx.t.APY}*`}</Typography>
        </Box>
        <Typography variant='subtitle2'>{ctx.t.yieldRateDisclaimer}<br /><br />{ctx.t.yieldRateDisclaimerEuroPar}</Typography>
      </Box>

    </Paper>
    <Link data-trk='l-simulateEarningBackBtn-to:previous' to={ctx.previousUrl()}>
      <FullWidthButton>{ctx.t.back}</FullWidthButton>
    </Link>
  </Page>
}

export default SimulateEarningPage

function calculateTotalEarn(conf: SimulationConfig): SimulationConfig {

  // RESET
  conf.totalInterestPremium = 0
  conf.totalInterestSmart = 0
  conf.totalInterestTraditional = 0
  conf.totalRecurringEarn = 0
  const nbDaysForPeriod = generalConfig.recurringTransferPeriods[conf.recurringTransferFrequency]

  for (let dayN = nbDaysForPeriod; dayN <= conf.savingDurationYear * 365 + nbDaysForPeriod - 1; dayN += nbDaysForPeriod) {
    if (calculateCompoundInterests) {
      forI(nbDaysForPeriod, () => {
        conf.totalInterestPremium += dailyPercentages.premium * (conf.totalInterestPremium + conf.totalRecurringEarn + conf.firstDeposit)
        conf.totalInterestSmart += dailyPercentages.smart * (conf.totalInterestSmart + conf.totalRecurringEarn + conf.firstDeposit)
        conf.totalInterestTraditional += dailyPercentages.traditional * (conf.totalInterestTraditional + conf.totalRecurringEarn + conf.firstDeposit)
      })
    } else {
      const total = conf.totalRecurringEarn + conf.firstDeposit * nbDaysForPeriod
      conf.totalInterestPremium += dailyPercentages.premium * total
      conf.totalInterestSmart += dailyPercentages.smart * total
      conf.totalInterestTraditional += dailyPercentages.traditional * total
    }

    conf.totalRecurringEarn += conf.recurringTransferAmount
  }
  conf.totalPremimuAmountAtTheEndOfPeriod = conf.totalRecurringEarn + conf.totalInterestPremium + conf.firstDeposit
  return conf
}


//----------------------------------------
// GRAPH
//----------------------------------------
type GraphEarningPropsType = { height: number, strokeColor: string, gradientColor1: string, gradientColor2?: string, amount: number, priceTagZindex: number }
function GraphEarning({ amount, height, strokeColor, gradientColor1, gradientColor2 = '#ffffff', priceTagZindex }: GraphEarningPropsType) {
  const theme = useTheme()
  const ctx = React.useContext(appCtx)

  const graphStyleDefault: any = { position: 'absolute', bottom: 0, left: 0, width: '100%', lineHeight: 0 /*FIX*/ }
  const dotStyle = { flex: '0 0 12px', border: '2px solid white', backgroundColor: strokeColor, borderRadius: '50%', height: 12, transform: 'translate(5px)' }
  const priceStyle: any = { flex: '1 0 96px', color: strokeColor, padding: 4, fontWeight: 700, fontSize: 'small', textAlign: 'center' }
  const dashedLineStyle = { flex: '1 1 100%', borderBottom: `1px dashed ${theme.palette.secondary.main}` }

  return <div style={graphStyleDefault}>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', position: 'absolute', top: -13, zIndex: priceTagZindex, lineHeight: 1.5 }}>
      {/* PRICE BAR */}
      <Paper style={priceStyle}>+{amount.toFixed(2) + ctx.user.currency.symbol}</Paper>
      <div style={dashedLineStyle}></div>
      <div style={dotStyle}></div>
    </div>
    <GraphSvg {...{ height, strokeColor, gradientColor1, gradientColor2 }} />
  </div>
}

type GraphSvgPropsType = { height: number, strokeColor: string, gradientColor1: string, gradientColor2?: string, [k: string]: any }
function GraphSvg({ height, strokeColor, gradientColor1, gradientColor2 = '#ffffff', ...props }: GraphSvgPropsType) {
  const gradientAddr = `linearGradient${gradientColor1.replace('#', '')}`
  return <svg width="100%" height={height} style={{ transition: 'all .5s' }} version="1.1" preserveAspectRatio="none" viewBox="0 0 132.29 105.83" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs>
      <linearGradient id={gradientAddr} x1="64.394" x2="64.394" y1=".83983" y2="106.8" gradientTransform="scale(3.7795)" gradientUnits="userSpaceOnUse">
        <stop style={{ stopColor: gradientColor1 }} offset="0" />
        <stop style={{ stopColor: gradientColor2 }} offset="1" />
      </linearGradient>
    </defs>
    <path transform="scale(.26458)" d="m500 0c-0.15666 0.035541-0.30635 0.071877-0.46289 0.10742a755.33 755.33 0 0 1-499.28 399.12c-0.090366 0.26628-0.17118 0.51072-0.26172 0.77734h500v-400z" style={{ fill: `url(#${gradientAddr})` }} />
    <path transform="scale(.26458)" d="m500 0c-0.15666 0.035541-0.30635 0.071877-0.46289 0.10742-99.159 200.37-281.99 346.52-499.28 399.12-0.090366 0.26628-0.17118 0.51072-0.26172 0.77734" style={{ fill: 'transparent', stroke: strokeColor }} />
  </svg>
}






//----------------------------------------
// TYPES
//----------------------------------------
type SimulationConfig = {
  firstDeposit: number
  recurringTransferAmount: number
  recurringTransferFrequency: t.RecurringTransferFrequency
  savingDurationYear: number
  totalInterestPremium: number
  totalInterestSmart: number
  totalInterestTraditional: number
  totalRecurringEarn: number
  totalPremimuAmountAtTheEndOfPeriod: number
}