import * as React from 'react'
import Typography from '@mui/material/Typography'
import { Link } from "react-router-dom";
import ArrowLeftRoundedIcon from '@mui/icons-material/ArrowLeftRounded'
import { useTheme } from '@mui/material/styles'
import { appCtx } from '../01_shared/appContext'

type PageTitlePropsType = {
  previousUrl?: string
  children: any
  positionAbsolute?: boolean
}

function PageTitle({ children, previousUrl, positionAbsolute = false }: PageTitlePropsType) {
  const theme = useTheme()
  const ctx = React.useContext(appCtx)
  const titleRef = React.useRef(null)

  const [titleOpacity, setTitleOpacity] = React.useState(0)


  React.useEffect(() => {
    const onScroll = (e) => {
      const opacityMax = 0.9
      const scrollAmount = window.scrollY
      setTitleOpacity(Math.max(0, Math.min(scrollAmount / 100, opacityMax)))
    }
    // clean up code
    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
  }, [])


  const leftArrowStyle: any = {
    position: 'absolute',
    color: theme.palette.text.secondary,
    top: -2,
    left: 0,
    zIndex: 999,
  }
  const wrapperStyle: any = {
    position: positionAbsolute ? 'absolute' : 'relative',
    height: 46,
    content: ' ',
    zIndex: 999,
    width: '100%',
  }
  const titleStyle: any = {
    position: 'fixed',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    height: 46,
    backgroundColor: `rgba(246, 248, 250, ${titleOpacity})`,
  }

  return <div data-trk='c-pageTitle' ref={titleRef} style={wrapperStyle}>
    <div style={titleStyle}>
      <Link data-trk='l-pageTitlePreviousLink-to:previous' to={previousUrl || ctx.previousUrl()} style={leftArrowStyle}>
        <ArrowLeftRoundedIcon sx={{ fontSize: 50 }} />
      </Link>
      <Typography textAlign='center' variant='h2' sx={{ flex: '1 1 100%' }}>{children}</Typography>
    </div>
  </div>
}

export default PageTitle