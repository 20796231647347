import * as React from 'react'
import { appCtx } from '../01_shared/appContext'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import { toggleDisplayMode } from '../Tracker/app-architecture-helpers'
import tracker from '../Tracker/Tracker'

// data-trk ignoreFile
const DevModeDrawer = ({ open, setOpen, version }) => {
  const ctx = React.useContext(appCtx)

  return <Drawer
    data-trk='ignore'
    anchor={'right'}
    open={open}
    onClose={() => setOpen(false)}
    onClick={() => setOpen(false)}
    PaperProps={{
      style: { fontFamily: 'monospace', color: 'lime', backgroundColor: '#242424' }
    }}
  >
    <Box px={2} sx={{ '& div': { cursor: 'pointer' } }}>
      <pre>{`${' '.repeat(23 - version.length)}v${version}
========================
╔╦╗╔═╗╦  ╦  ╔╦╗╔═╗╔╦╗╔═╗
 ║║║╣ ╚╗╔╝  ║║║║ ║ ║║║╣ 
═╩╝╚═╝ ╚╝   ╩ ╩╚═╝═╩╝╚═╝
========================
`}<br />

        --- INFO LAYER ---
        <div onClick={() => toggleDisplayMode('architecture')}>SHOW APP STRUCTURE</div>
        <div onClick={() => toggleDisplayMode('displayTime')}>SHOW VISIBILITY</div>
        <div onClick={() => toggleDisplayMode('clicks')}>SHOW CLICKS</div>
        <div onClick={() => toggleDisplayMode('missedClicks')}>SHOW MISSED CLICKS</div>
        <div onClick={() => toggleDisplayMode('rageClicks')}>SHOW RAGE CLICKS</div>
        <div onClick={() => toggleDisplayMode('none')}>RESET INFO LAYER</div>
        <br />
        ---- SETTINGS ----
        <div onClick={() => tracker.showEventLog()}>SHOW EVENTS LOG</div><br />

        <div onClick={() => ctx.logout()}>LOGOUT</div>

      </pre>
    </Box>
  </Drawer>
}

export default DevModeDrawer
