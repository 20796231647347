import React from 'react';
import translations from './translations'
import generalConfig from './general-config'
import * as t from './types'
import { convert } from './services/convert'
import applicationError from 'src/00_core/application-error'
import vaultDefaultConfig from 'src/vaults/VaultDefaultConfig'
import { navigate, previousNavItem, navPrevious, previousUrl } from '../00_core/navigation-helper'
import themeVarz from './theme/theme-varz'

import { forI, capitalize1st, miniTemplater, isset, addDays } from 'topkat-utils'

export const defaultUser: t.UserType = {
  firstName: 'A',
  lastName: 'A',
  lang: 'en',
  dateFormat: 'en-US',
  currency: generalConfig.currency.euro,
  totalAmount: 1552.5,
  lastDayRevenue: 2.22,
  hasSeenVaultVideo: false,
  accounts: ([
    {
      id: 'euro1',
      ...generalConfig.accountType.fiat,
      name: 'My Euro Account',
      totalAmount: 3000,
      creationDate: addDays(new Date(), -15),
    },
    {
      // DELETEME just for testing
      id: 'vault1',
      ...generalConfig.accountType.vault,
      name: 'Savings',
      totalAmount: 1500,
      color: '#ffffff',
      backgroundColor: themeVarz.primaryBlue,
      isSmart: true,
      isPremium: true,
      savingGoalActive: true,
      savingGoal: 10000,
      deadlineActive: true,
      deadLine: addDays(new Date(), 56),
      recurringTransferActive: true,
      recurringTransferFrequency: 'monthly',
      recurringTransferAmount: 150,
      recurringTransferStartDate: new Date(),
      recurringTransferSourceAccountId: 'euro1',
      creationDate: addDays(new Date(), -12),
    },
  ] as t.UserAccountType[]),
  transactions: [
    { type: 'deposit', amountSource: 1500, amountTarget: 1500, depositDate: addDays(new Date(), -1, 'date'), accountSource: 'external', accountTarget: 'euro1', currencySource: generalConfig.currency.euro, currencyTarget: generalConfig.currency.euro, locked: false, exchangeRate: 1 },
    { type: 'deposit', amountSource: 1500, amountTarget: 1500, depositDate: addDays(new Date(), -2, 'date'), accountSource: 'external', accountTarget: 'euro1', currencySource: generalConfig.currency.euro, currencyTarget: generalConfig.currency.euro, locked: false, exchangeRate: 1 },

    { type: 'deposit', amountSource: 1500, amountTarget: 1500, depositDate: addDays(new Date(), -2, 'date'), accountSource: 'external', accountTarget: 'vault1', currencySource: generalConfig.currency.euro, currencyTarget: generalConfig.currency.euro, locked: false, exchangeRate: 1 },
    // EARN TRANSACTIONS
    ...forI(31, (i) => ({ type: 'earn', amountSource: -1, amountTarget: Math.random(), depositDate: addDays(new Date(), -(i + 1), 'date'), accountSource: 'external', accountTarget: 'vault1', currencySource: generalConfig.currency.euro, currencyTarget: generalConfig.currency.euro, locked: false, exchangeRate: 1 } as t.AccountTransaction)),
  ]
}

export const defaultCtx = {
  user: defaultUser,
  t: translations.en,
  // <defined in app.tsx>
  changeLanguage(lang: string) { },
  refresh() { }, // update all component
  logout() { },
  // </defined in app.tsx>
  updateUser(fieldsToUpdate: Partial<t.UserType> = {}) {
    const newUser: t.UserType = Object.assign({}, defaultUser, this.user, fieldsToUpdate)
    newUser.accounts.forEach(account => account.totalAmount = 0)
    for (const transaction of newUser.transactions) {
      if (transaction.type === 'deposit') {
        if (transaction.accountSource !== 'external') {
          const accountSource = this.user.accounts.find(acc => acc.id === transaction.accountSource) as t.UserAccountType
          accountSource.totalAmount -= transaction.amountSource
        }
        const accountTarget = this.user.accounts.find(acc => acc.id === transaction.accountTarget) as t.UserAccountType
        accountTarget.totalAmount += transaction.amountTarget
      }
    }

    newUser.totalAmount = newUser.accounts.reduce((sum, actualAccount) => {
      return sum + convert(actualAccount.currency.id, newUser.currency.id, actualAccount.totalAmount)
    }, 0)

    this.user = newUser
    this.refresh()
  },
  getLastTransactions(n: number = 1, filterFn: (t: t.AccountTransaction) => boolean) {
    this.user.transactions.sort((a, b) => a.depositDate.getTime() - b.depositDate.getTime())
    const minI = Math.max(0, this.user.transactions.length - n - 1)
    return this.user.transactions.filter((t, i) => i > minI && filterFn(t))
  },
  //----------------------------------------
  // ACCOUNTS
  //----------------------------------------
  activeAccount: undefined as t.UserAccountType | undefined | null,
  ensureActiveAccount(id?: string): t.UserAccountType {
    if (this.activeAccount && (typeof id === 'undefined' || this.activeAccount.id === id)) {
      // RETURN ACTIVE ACCOUNT
      return this.activeAccount
    } else if (id && this.doAccountExists(id)) {
      // GET EXISTING ACCOUNT
      this.activeAccount = this.getAccountById(id)
      //  if(doNotReRender === false) this.refresh()
    } else if (!this.activeAccount) {
      // CREATE NEW ACCOUNT
      this.activeAccount = vaultDefaultConfig(this)
      this.user.accounts.push(this.activeAccount as t.UserAccountType)
      this.updateUser()
    }
    return this.activeAccount as t.UserAccountType
  },
  createNewUserAccount(accountType: t.AccountTypeEnum, previewMode = false): t.UserAccountType {
    const nbAccountType = this.user.accounts.filter(acc => acc.type === accountType).length
    const nbSuffix = nbAccountType > 1 ? nbAccountType + 1 + '' : ''
    const newAccount: t.UserAccountType = {
      ...generalConfig.accountType[accountType],
      id: accountType + nbSuffix,
      name: miniTemplater(this.t.myAccountName, { accountName: capitalize1st(accountType) + (nbSuffix ? ' ' + nbSuffix : '') }),
      totalAmount: 0,
      transactions: [],
    }
    if (previewMode === false) {
      this.user.accounts.push(newAccount)
      this.updateUser()
    }
    return newAccount
  },
  editActiveAccount(accountConfig: Partial<t.UserAccountType>) {
    return this.editAccount(this.ensureActiveAccount().id, accountConfig)
  },
  editAccount(accountId: string, accountConfig: Partial<t.UserAccountType>) {
    const account = this.getAccountById(accountId)
    Object.assign(account, accountConfig)
    this.updateUser() // refresh amounts...etc
  },
  /** PreviewMode is used to NOT ADD transaction to user but just return the transaction. You may add it later */
  userAccountTransaction(fromAccount: t.UserAccountType | string, toAccount: t.UserAccountType | string, type: t.TransactionType, amountSource: number, exchangeRate, transactionLockedDaysNb?: number, previewMode = false): t.AccountTransaction {
    const accountSource = typeof fromAccount === 'string' ? this.getAccountById(fromAccount) : fromAccount
    const accountTarget = typeof toAccount === 'string' ? this.getAccountById(toAccount) : toAccount
    if (!accountSource || !accountTarget) throw applicationError('One of account is not defined when entering a transaction', { accountSource, accountTarget, })
    const isLocked = isset(transactionLockedDaysNb)
    const today = new Date()

    const transaction: t.AccountTransaction = {
      type,
      amountSource,
      amountTarget: amountSource * exchangeRate,
      accountTarget: accountTarget.id,
      accountSource: accountSource.id,
      currencySource: accountSource.currency,
      currencyTarget: accountTarget.currency,
      exchangeRate,
      depositDate: today,
      ...(isLocked ? {
        locked: true,
        redeemDate: (addDays(today, transactionLockedDaysNb, 'date') as Date)
      } : { locked: false })
    }
    if (previewMode === false) {
      this.user.transactions.push(transaction)
      this.updateUser()
    }
    return transaction
  },
  getAccountByType(type: t.AccountTypeEnum, createIfNotSet: boolean = false): t.UserAccountType {
    let account = this.user.accounts.find(account => account.type === type)
    if (!account) {
      if (createIfNotSet) account = this.createNewUserAccount(type)
      else throw applicationError('Account not set', { type, account })
    }
    return account
  },
  getAccountByTypeIfExists(type?: string): t.UserAccountType | undefined {
    return this.user.accounts.find(account => account.type === type)
  },
  getAccountById(id?: string): t.UserAccountType {
    const account = this.user.accounts.find(account => account.id === id)
    if (!account) throw applicationError('Account not set', { id, account })
    return account
  },
  doAccountExists(id?: string) {
    return this.user.accounts.some(account => account.id === id)
  },
  convert,
  //----------------------------------------
  // NAVIGATION
  //----------------------------------------
  navigate,
  previous: navPrevious,
  previousNavItem,
  previousUrl,
}


// https://www.digitalocean.com/community/tutorials/react-manage-user-login-react-context
export const appCtx = React.createContext(defaultCtx)




