import * as React from 'react'
import CardMedia from '@mui/material/CardMedia'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import { Link } from "react-router-dom"
import Box from '@mui/material/Box'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { ReactComponent as HorseIcon } from '../01_shared/assets/horse-icon.svg'
import FullWidthButton from '../01_shared/FullWidthButton'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import Page from '../01_shared/Page'
import VaultImage from './VaultImage'
import RoundIcon from 'src/01_shared/RoundIcon'
import { useTheme } from '@mui/material/styles'
import { appCtx } from '../01_shared/appContext'
import FullScreenDialogInfo from 'src/01_shared/FullScreenDialogInfo'
import StarRoundedIcon from '@mui/icons-material/StarRounded'
import generalConfig from '../01_shared/general-config'
import VaultBuilderItems from './VaultBuilderItems'
import BottomDrawer from '../01_shared/BottomDrawer'



const NewSavingVaultPage = () => {

  const theme = useTheme()
  const ctx = React.useContext(appCtx)
  const conf = ctx.ensureActiveAccount()
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [imageDrawerOpen, setImageDrawerOpen] = React.useState(false)


  const vaultImgBoxStyle = { position: 'relative', borderBottomLeftRadius: 25, borderBottomRightRadius: 25, backgroundColor: theme.palette.bgDarker.main }

  const noChanges = conf.name === ctx.t.newVaultDefaultTitle &&
    !conf.isPremium && !conf.isSmart &&
    conf.firstDepositAmount === 0 &&
    !conf.savingGoalActive &&
    !conf.deadlineActive &&
    !conf.recurringTransferActive

  return !ctx.user.hasSeenVaultVideo ?
    <VideoDisplayComponent /> :
    <Page dataTrkName='vaultBuilder' title={ctx.t.buildYourVault} titleAbsolute>
      <Box p={2} mb={2} sx={vaultImgBoxStyle}>
        <div className='center' style={{ height: 300 }}>
          <VaultImage account={conf} />
        </div>
        <Link data-trk='l-vaultCustomTitleAction-to:/vault/saving/config' to='/vault/saving/config' >
          <Typography variant='h2'>
            {conf.name || ctx.t.newVaultDefaultTitle}
            <EditRoundedIcon style={{ float: 'right' }} />
          </Typography>
        </Link>
      </Box>
      <Box p={2}>
        <Typography fontWeight={700}>{ctx.t.vaultMainSectionDescriptionTitle}</Typography>
        <Typography sx={{ mb: 3 }}>{ctx.t.vaultMainSectionDescription}</Typography>

        <VaultBuilderItems firstTime account={conf} />

        {noChanges ? <FullWidthButton variant='outlined' data-trk='a-cancelBtn-action:cancel' onClick={() => ctx.navigate('/')}>
          {ctx.t.cancel}
        </FullWidthButton>
          : <FullWidthButton data-trk='a-validateBtn-action:validate' onClick={() => setImageDrawerOpen(true)}>
            {ctx.t.newVaultValidateBtnText}
          </FullWidthButton>}
        {/* <FullWidthButton variant={noChanges ? 'text'} data-trk='a-validateBtn-action:validate' onClick={() => noChanges ? ctx.navigate('/') : setImageDrawerOpen(true)}>
          {ctx.t.newVaultValidateBtnText}
        </FullWidthButton> */}
        <Typography variant='body2' textAlign='center' my={2}>{ctx.t.newVaultValidateBtnUnderDescription}</Typography>


        <BottomDrawer open={imageDrawerOpen} setOpen={setImageDrawerOpen} >
          <Box sx={{ p: 2, textAlign: 'center', }}>
            <div className='center' style={{ height: 150 }}>
              <VaultImage account={conf} />
            </div>

            <Typography fontWeight={700}>{ctx.t.vaultCreationConfirmDrawerMessage}</Typography>

            <FullWidthButton data-trk='a-createNewVaultDrawerValidate-action:validate' onClick={() => setDialogOpen(true)}>
              {ctx.t.vaultDrawerConfirmBtn}
            </FullWidthButton>

            <FullWidthButton data-trk='a-createNewVaultDrawerCancel-action:cancel' variant='outlined' onClick={() => setImageDrawerOpen(false)}>
              {ctx.t.vaultDrawerCancelBtn}
            </FullWidthButton>

            <Typography variant='body2' textAlign='center' my={2}>{ctx.t.newVaultValidateBtnUnderDescription}</Typography>
          </Box>
        </BottomDrawer>


        <FullScreenDialogInfo isOpen={dialogOpen} setOpen={setDialogOpen} onClose={() => ctx.navigate(`/vault/${conf.id}`)} >
          <RoundIcon Icon={StarRoundedIcon} iconSize={60} />
          <Typography sx={{ mt: 2, fontWeight: 700, textAlign: 'center', }}>{ctx.t.congratulation}</Typography>
          <Typography variant='primary' sx={{ textAlign: 'center' }}>{ctx.t.yourVaultHasBeenCreated}</Typography>
        </FullScreenDialogInfo>
      </Box>
    </Page>
}

export default NewSavingVaultPage

//----------------------------------------
// VIDEO DISPLAY COMPONENT
//----------------------------------------
function VideoDisplayComponent() {
  const theme = useTheme()
  const ctx = React.useContext(appCtx)

  const mainColor = theme.palette.text.secondary

  const [[progress, duration], setProgress] = React.useState([0, 50])

  return <Page dataTrkName='newSavingVaultVideoTutorial'>
    <Box p={2}>
      <Box mb={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <HorseIcon style={{ flex: '0 0 24px' }} stroke={mainColor} />
        <Typography sx={{ flex: '1 1 100%', mt: '5px', ml: 1 }}>AKTIO Vaults</Typography>
        <Typography sx={{ flex: '1 1 100%', mt: '5px', mr: 1 }} textAlign={'right'}>{
          (Math.round(duration / 60) - Math.round((progress - 1) / 60)).toString().padStart(2, '0') + ':' + Math.round(duration % 60 - (progress - 1) % 60).toString().padStart(2, '0')
        }</Typography>
        <CloseRoundedIcon data-trk='a-closeVideoBtn' onClick={() => ctx.updateUser({ hasSeenVaultVideo: true })} />
      </Box>
      <LinearProgress variant="determinate" value={Math.min(((100 / duration) * progress), 100)} />
    </Box>

    <CardMedia
      component='video'
      image={generalConfig.vaults.firstTimeBuildVideoUrl}
      autoPlay
      onEnded={() => ctx.updateUser({ hasSeenVaultVideo: true })}
      onLoad={e => setProgress([0, e.target.duration])}
      onTimeUpdate={e => setProgress([e.target.currentTime, e.target.duration])}
      style={{ maxHeight: 'calc(100vh - 80px)' }}
    />
  </Page>
}

