

import { toast, Slide } from 'react-toastify'
import { useState } from 'react'
import generalConfig from '../01_shared/general-config'
import * as t from '../01_shared/types'

import { isset } from 'topkat-utils'


function applicationError(err): void
function applicationError(msg: string, err): void
function applicationError(msgOrErr: string | t.ObjectGeneric, errRaw: t.ObjectGeneric = {}): any {
    const msgStr = typeof msgOrErr === 'string' ? msgOrErr : msgOrErr.msg || msgOrErr.message || 'Error'
    const err: t.ObjectGeneric = (typeof msgOrErr === 'string' ? errRaw : msgOrErr) || {}
    toast.error(<ToastContainer msg={`${isset(err.code) ? err.code + ': ' : ''}${msgStr}`} extraInfos={err} />, {
        position: "top-right",
        autoClose: generalConfig.errDisplayTime,
        hideProgressBar: false,
        pauseOnHover: true,
        closeOnClick: false,
        pauseOnFocusLoss: true,
        icon: false,
        transition: Slide,
    });
    console.error(err)
    return err
}

type ToasContainerProps = { msg: string, extraInfos?: t.ObjectGeneric, closeToast?: any }
function ToastContainer({ msg, extraInfos, closeToast }: ToasContainerProps) {
    const [isOpen, setOpen] = useState(true)
    return (
        <div>
            <div onClick={closeToast}>{msg.replace(/([A-Z])/g, ' $1').toLowerCase()}<br /></div>
            {extraInfos ? <div className='toaster-extra-infos' onClick={() => setOpen(!isOpen)}>
                <span style={{ opacity: .5 }}>extra infos</span><br />
                {isOpen ? <pre className='overflow-auto'>{JSON.stringify(extraInfos, null, 2)}</pre> : null}
            </div> : null}
        </div>
    )
}

export default applicationError