import * as React from 'react'
import { appCtx } from '../01_shared/appContext'
import Page from '../01_shared/Page'
import TransferMoney from '../01_shared/TransferMoney'
import * as t from '../01_shared/types'
import FullScreenDialogInfo from 'src/01_shared/FullScreenDialogInfo'
import RoundIcon from 'src/01_shared/RoundIcon'
import StarRoundedIcon from '@mui/icons-material/StarRounded'
import Typography from '@mui/material/Typography'

const VaultFirstDepositPage = () => {
  const ctx = React.useContext(appCtx)
  const conf = ctx.ensureActiveAccount()
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const onTransferSuccess = (transaction: t.AccountTransaction) => {
    conf.transactionsForFirstDeposit.push(transaction)
    conf.firstDepositAmount += transaction.amountTarget
    if (transaction && transaction.amountTarget) setDialogOpen(true)
    else ctx.previous()
  }
  const sourceAccount = ctx.getAccountByType('fiat')
  const lastTransactionAmount = conf.transactionsForFirstDeposit.length ? conf.transactionsForFirstDeposit[conf.transactionsForFirstDeposit.length - 1].amountTarget : 0

  return <Page dataTrkName='vaultFirstDeposit' p={2} title={ctx.t.firstDepositPageTitle}>
    <TransferMoney
      transferAmount={0}
      lockTargetAccountSelect
      sourceAccount={sourceAccount}
      targetAccount={conf as t.UserAccountType}
      onTransferSuccess={onTransferSuccess}
    />

    <FullScreenDialogInfo isOpen={dialogOpen} setOpen={setDialogOpen} onClose={ctx.previous} >
      <RoundIcon Icon={StarRoundedIcon} iconSize={60} />
      <Typography sx={{ p: 2, fontWeight: 700, textAlign: 'center', }}>{ctx.t.firstDepositSuccess({
        amount: lastTransactionAmount.toFixed() + ' ' + sourceAccount.currency.short,
      })}</Typography>
    </FullScreenDialogInfo>
  </Page>
}

export default VaultFirstDepositPage