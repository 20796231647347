import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import { appCtx } from '../01_shared/appContext'
import Paper from '@mui/material/Paper'
import * as t from '../01_shared/types'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import ListItemText from '@mui/material/ListItemText'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import StarRoundedIcon from '@mui/icons-material/StarRounded'
import { convert } from './services/convert'
import List from '@mui/material/List'




type TransactionListProps = { transactions: t.AccountTransaction[], maxN?: number }
function TransactionList({ transactions, maxN = 3 }: TransactionListProps) {
  const theme = useTheme()
  const ctx = React.useContext(appCtx)
  return <Paper data-trk='c-transactionList'>
    <List>
      {transactions.map(({ type, currencyTarget, amountTarget, depositDate }, i) => {
        if (i >= maxN) return null
        return <ListItem key={i} alignItems="center">
          <ListItemAvatar sx={{ mr: 2 }}>
            <Avatar variant="rounded" sx={{ bgcolor: theme.palette.bgDarkest.main }}>
              {/* 'withdraw' | 'recurringTransfer' | 'deposit' | 'buy' | 'sell' | 'earn' */}
              {['withdraw', 'sell'].includes(type) ?
                <ArrowBackRoundedIcon /> :
                type === 'earn' ? <StarRoundedIcon /> :
                  <ArrowForwardRoundedIcon />}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<b>{`${ctx.t.transactionTypes[type]} - ${currencyTarget.long.toUpperCase()}`}</b>}
            secondary={new Intl.DateTimeFormat(ctx.user.dateFormat, { dateStyle: 'full' }).format(depositDate)}
          />
          <ListItemText
            primary={<span style={{ fontWeight: 'bold' }}>{`+${amountTarget.toFixed(2)} ${currencyTarget.symbol}`}</span>}
            secondary={<span>{`${convert(currencyTarget.id, ctx.user.currency.id, amountTarget).toFixed(2)} ${ctx.user.currency.symbol}`}</span>}
            style={{ textAlign: 'right' }}
          />
        </ListItem>
      })}
    </List>
  </Paper>
}

export default TransactionList