import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import { appCtx } from '../01_shared/appContext'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import * as t from '../01_shared/types'
import FullWidthButton from './FullWidthButton'
import CachedRoundedIcon from '@mui/icons-material/CachedRounded'
import { convert } from './services/convert'
import AmountInput from './AmountInput'
import SelectUserAccount from './SelectUserAccount'
import applicationError from 'src/00_core/application-error'
import generalConfig from '../01_shared/general-config'

import { isset } from 'topkat-utils'

const TransferMoney = ({
  lockTransferSource = false,
  lockTransferTarget = false,
  lockSourceAccountSelect = false,
  lockTargetAccountSelect = false,
  transferAmount = 0,
  sourceAccountId,
  targetAccountId,
  transactionLockedPeriod,
  onTransferSuccess,
  targetAccount,
  sourceAccount,
  PREVIEW_MODE = false, // use this if you want to create transaction but not add it to user (you may add it later)
}: t.TransferMoneyPropsType) => {

  const ctx = React.useContext(appCtx)

  const sourceDefault = sourceAccount || ctx.getAccountById(sourceAccountId)
  const targetDefault = targetAccount || ctx.getAccountById(targetAccountId)

  const [sourceAccount_, setSourceAccount] = React.useState(sourceDefault)
  const [targetAccount_, setTargetAccount] = React.useState(targetDefault)

  const [amountSource, setAmountSource] = React.useState(transferAmount)
  const [exchangeRate, setExchangeRate] = React.useState(convert(sourceAccount_.currency.id, targetAccount_.currency.id, 1))
  const refreshChangeRate = () => setExchangeRate(convert(sourceAccount_.currency.id, targetAccount_.currency.id, 1))

  /** FIX when needing to pass state trough link in react router */
  const sourceIcon = generalConfig.accountType[sourceAccount_.type].icon
  const targetIcon = generalConfig.accountType[targetAccount_.type].icon

  if (!isset(sourceAccount_, targetAccount_, exchangeRate)) applicationError(`One of sourceAccount_, targetAccount_, exchangeRate is not defined`, { sourceAccount_, targetAccount_, exchangeRate })
  return <>
    <Paper data-trk='c-transferMoney' sx={{ p: 2 }}>
      <Box mb={2} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>

        {/* SOURCE ACCOUNT */}
        <Typography component={'span'}>
          {ctx.t.from}&nbsp;
          <b>{lockSourceAccountSelect ?
            sourceDefault.name :
            <SelectUserAccount data-trk={`i-selectAccountSource-defaultType:${sourceAccount_.type}`} value={sourceAccount_.id} onChange={accId => setSourceAccount(ctx.getAccountById(accId))} />
          }</b>
        </Typography>
        <Typography component={'span'}>
          {ctx.t.balance}: <b>{sourceAccount_.totalAmount}</b>
        </Typography>
      </Box>

      {/* SOURCE AMOUNT */}
      <AmountLineInput data-trk={`i-transferAmountSource`} autoFocus Icon={sourceIcon} value={amountSource} currency={sourceAccount_.currency.short} disabled={lockTransferSource} onChange={val => setAmountSource(val)} />

      {/* CONVERSION PREVIEW */}
      <Box>
        <Typography variant='primary' component='div' sx={{ my: 3, fontSize: 14, textAlign: 'center', }}>
          1 {sourceAccount_.currency.long.toUpperCase()} = {exchangeRate.toFixed(2)} {targetAccount_.currency.long.toUpperCase()}
          <CachedRoundedIcon data-trk='a-exchangeRateRefreshBtn' onClick={refreshChangeRate} style={{ position: 'relative', marginLeft: 4, bottom: -4 }} />
        </Typography>
      </Box>

      {/* TARGET ACCOUNT */}
      <Box mb={2}>
        <Typography component={'span'}>
          {ctx.t.to}
          <b>{lockTargetAccountSelect ?
            ' ' + targetAccount_.name :
            <SelectUserAccount data-trk={`i-selectAccountSource-defaultType:${targetAccount_.type}`} value={targetAccount_.id} onChange={accId => setTargetAccount(ctx.getAccountById(accId))} />
          }</b>
        </Typography>
      </Box>

      {/* TARGET AMOUNT */}
      <AmountLineInput ata-tracker={`i-transferAmountTarget`} Icon={targetIcon} value={amountSource * exchangeRate} currency={targetAccount_.currency.short} disabled={lockTransferTarget} onChange={val => setAmountSource(val / exchangeRate)} />


    </Paper>
    {/* BTN */}
    <FullWidthButton data-trk='a-validateBtn-action:validate' onClick={() => {
      const transaction = ctx.userAccountTransaction(sourceAccount_.id, targetAccount_.id, 'deposit', amountSource, exchangeRate, transactionLockedPeriod, PREVIEW_MODE)
      onTransferSuccess(transaction)
    }}>{ctx.t.transfer}</FullWidthButton>

    <FullWidthButton data-trk='a-cancelBtn-action:cancel' variant='outlined' onClick={ctx.previous}>{ctx.t.cancel}</FullWidthButton>
  </>
}

export default TransferMoney










function AmountLineInput({ Icon, currency, value, disabled, onChange, ...props }: AmountLineInputProps) {
  const theme = useTheme()

  const iconSize = 39
  const wrapperStyle = {
    display: 'flex',
    '& .amountIcon': { backgroundColor: disabled ? theme.palette.bgDarkest.main : theme.palette.primary.main, color: 'white', marginRight: 2, flex: `0 0 ${iconSize}px`, height: iconSize, borderRadius: 1 },
  }

  const input = <Box sx={wrapperStyle}>
    <div className='amountIcon center'>{<Icon fill='#ffffff' />}</div>
    <AmountInput
      currency={currency}
      value={value}
      disabled={disabled}
      onChange={onChange}
      variant={'outlined'}
      {...props}
    />
  </Box>
  return input
}




//----------------------------------------
// TYPES
//----------------------------------------
type AmountLineInputProps = { Icon: t.IconComponentType, currency: string, disabled: boolean, value: number, [k: string]: any, onChange: (val: number) => any }

