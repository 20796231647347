export type ComponentConfigType = {
  id: string
  type: string // 'a' | 'l' | 'c',
  addr: string
  url: string
  parents: string[]
  // childs: string[]
  isMultiple: boolean
  isVisible?: boolean
  displayTime: number
  elm: Element
  clicks: number
  rageClicks: number
  missedClicks: number
  inputEditHistory: [number, ...string[]][] // timestamp + keys
  rageClickTracker: { timer?: any, nbClick: number }
  params?: { [k: string]: string } // TODO parse boolean and numbers ?
}


export const componentConfigs: { [k: string]: ComponentConfigType } = {}
