
import { Link } from "react-router-dom";
import BottomNavigation from '@mui/material/BottomNavigation';
import { useTheme } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';
import Paper from '@mui/material/Paper';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { ReactComponent as DashboardIcon } from './assets/app-bottom-bar/Menu 1.svg'
import { ReactComponent as PlayIcon } from './assets/app-bottom-bar/Menu 2.svg'
import { ReactComponent as LightningIcon } from './assets/app-bottom-bar/Menu 3.svg'
// import { ReactComponent as CardIcon } from './assets/app-bottom-bar/Menu 4.svg'


// data-trk ignoreFile
const FixedBottomNavMenu = ({ actualPage, setActualPage }) => {
  const theme = useTheme()

  const config = {
    maxWidth: 250,
    activeMenuItemColor: theme.palette.secondary.main,
    inactiveColor: theme.palette.text.disabled,
    shadow: `0px 5px 5px -3px rgba(0,0,0,0.05),0px 8px 10px 1px rgba(0,0,0,0.05),0px 3px 14px 2px rgba(0,0,0,0.1)`,
  }

  const style = {
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: 1,
    pb: 0,
    '> a': { minWidth: 'auto', maxWidth: 50 },
    '& .MuiBottomNavigationAction-label': { display: 'none' },
    '& .MuiButtonBase-root': { paddingTop: '6px' },
  }
  const activeButtonCondition = condition => ({ fill: condition ? config.activeMenuItemColor : config.inactiveColor })

  return (
    <div style={{ position: 'fixed', bottom: 20, left: 0, right: 0, display: 'flex', justifyContent: 'center', zIndex: 99 }}>
      <Paper data-trk='c-mainFixedBottomNav' sx={{ maxWidth: config.maxWidth, width: '80vh', minWidth: '150px', borderRadius: 40, boxShadow: config.shadow, zIndex: 99 }}>
        <BottomNavigation showLabels={false} value={actualPage} onChange={(e, newVal) => setActualPage(newVal)} sx={style}>
          <BottomNavigationAction
            data-trk={`l-bottomNavigationDashboardLink-to:dashboard`}
            value="dashboard"
            icon={<SvgIcon component={DashboardIcon} style={activeButtonCondition(actualPage === 'dashboard')} />}
            component={Link} to="/" />
          <BottomNavigationAction
            data-trk={`l-bottomNavigationPage1Link-to:page1`}
            value="menu2"
            icon={<SvgIcon component={PlayIcon} style={activeButtonCondition(actualPage === 'menu2')} />}
            component={Link} to='/page1' />
          <BottomNavigationAction
            data-trk={`l-bottomNavigationPage2Link-to:page2`}
            value="menu3"
            icon={<SvgIcon component={LightningIcon} style={activeButtonCondition(actualPage === 'menu3')} />}
            component={Link} to='/page2' />
        </BottomNavigation>
      </Paper>
    </div>
  );
};

export default FixedBottomNavMenu;
