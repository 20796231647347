import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import Box from '@mui/material/Box'
import { TransitionProps } from '@mui/material/transitions';

// data-trk ignoreFile
const Transition = React.forwardRef(function Transition(props: TransitionProps & { children: React.ReactElement<any, any>; }, ref: React.Ref<unknown>,) {
    return <Slide direction="up" ref={ref} {...props} />;
});

let fullScreenDialogTimeout
type FullScreenDialogInfoPropsType = {
    isOpen: boolean
    setOpen: (isOpen: boolean) => any
    timeout?: number
    children?: any
    onClose?: () => any
}
const FullScreenDialogInfo = ({ isOpen, setOpen, timeout = 3000, children, onClose = () => null }: FullScreenDialogInfoPropsType) => {

    const closeDialog = () => {
        setOpen(false)
        onClose()
    }

    if (isOpen) {
        clearTimeout(fullScreenDialogTimeout)
        fullScreenDialogTimeout = setTimeout(() => closeDialog(), timeout)
    }

    return <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        onClick={() => {
            clearTimeout(fullScreenDialogTimeout)
            closeDialog()
        }}
        fullWidth
    >
        <Box className='center' sx={{ p: 2, position: 'relative', minHeight: 'calc(100vh - 86px)' }}>
            {children}
        </Box>
    </Dialog>
}

export default FullScreenDialogInfo