import * as React from 'react'
import { appCtx } from '../01_shared/appContext'
import Page from '../01_shared/Page'
import { useLocation } from "react-router-dom"
import * as t from '../01_shared/types'
import TransferMoney from './TransferMoney'
import Box from '@mui/material/Box'


const transferDefault = {
  lockTransferSource: false,
  lockTransferTarget: false,
  lockSourceAccountSelect: false,
  lockTargetAccountSelect: false,
  transferAmount: 0,
}

const WealthHubPage = () => {
  const ctx = React.useContext(appCtx)
  const location = useLocation()
  const data = Object.assign({}, transferDefault, location.state || {}) as t.TransferMoneyPropsType

  return <Page dataTrkName='wealthHub' title={ctx.t.wealthHub}>
    <Box p={2}>
      <TransferMoney {...data} />
    </Box>
  </Page>
}

export default WealthHubPage