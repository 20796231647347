import * as React from 'react'
import { appCtx } from '../01_shared/appContext'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'

type AmountInputProps = { disabled?: boolean, value: number, currency: string, [k: string]: any, onChange: (value: number) => any }

// tracker should be put on parent component
// data-trk ignoreFile
function AmountInput({ value, onChange, disabled = false, currency, ...props }: AmountInputProps) {
  const ctx = React.useContext(appCtx)

  const [inputVal, setInputVal] = React.useState(value.toString())

  const isOutlinedVariant = typeof props.variant !== 'undefined' && props.variant === 'outlined'

  const input = <TextField
    variant={isOutlinedVariant ? 'outlined' : 'standard'}
    size='small'
    disabled={disabled}
    value={value.toString() !== inputVal && ! /[.]$/.test(inputVal) ? value : inputVal}
    InputProps={{
      type: 'number',
      endAdornment: <InputAdornment position="end">{currency || ctx.user.currency.short}</InputAdornment>,
      style: { fontWeight: 700, fontSize: 18 },
      inputProps: { style: isOutlinedVariant ? { paddingBottom: 4 } : {} },
      autoComplete: 'off',
    }}
    style={{ fontWeight: 700, fontSize: 18 }}
    fullWidth
    onChange={e => {
      e.target.value = e.target.value
        .replace(/,/, '.') // normalize comma
        .replace(/^0\./, '@') // allow replacing 01 => 1 without replacing 0.1 => .1
        .replace(/^0+/, '') // replace leading zeros
        .replace(/@/, '0.') // set back leading zero when comma

      if (e.target.value === '') e.target.value = '0'

      if (/^-?\d+\.?\d{0,2}$/.test(e.target.value)) {
        setInputVal(e.target.value)
        if (!/[.]$/.test(e.target.value)) onChange(parseFloat(e.target.value))
      }
    }}
    {...props}
  />

  return input
}


export default AmountInput