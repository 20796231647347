

/** Will VALIDATE FORM
 * and return undefined if form is not valid
 * IF VALID it will return the result as a JAVASCRIPT OBJECT
 * Input NAME will be used form json props
 * For ARRAY values the name must end with []. Eg: <input name='myValues[]' />
 */
export function form2json(e, formElement) {
  const isValid = formElement.checkValidity()
  formElement.reportValidity()
  e.preventDefault()
  if (!isValid) return;
  const data = new FormData(formElement)
  const jsonData = Object.fromEntries(data.entries()) // https://www.learnwithjason.dev/blog/get-form-values-as-json
  for (const [propName, value] of Object.entries(jsonData)) {
    if (/\[\]$/.test(propName) && typeof value === 'string') data[propName] = value.split(',')
  }
  return jsonData
}