import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import { appCtx } from '../01_shared/appContext'
import Page from '../01_shared/Page'
import Typography from '@mui/material/Typography'
import SimpleVideoPlayer from '../01_shared/SimpleVideoPlayer'
import Box from '@mui/material/Box'
import { Link } from "react-router-dom";
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded'
import { ReactComponent as ParIcon } from '../01_shared/assets/par-icon.svg'
import { ReactComponent as HorseIcon } from '../01_shared/assets/horse-icon.svg'
import Paper from '@mui/material/Paper'
import Switch from '@mui/material/Switch'
import BottomDrawer from '../01_shared/BottomDrawer'
import FullWidthButton from 'src/01_shared/FullWidthButton'
import FullScreenDialogInfo from '../01_shared/FullScreenDialogInfo'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import generalConfig from '../01_shared/general-config'
import { convert } from '../01_shared/services/convert'

const DailyInterestConfigPage = () => {
  const theme = useTheme()
  const ctx = React.useContext(appCtx)
  const conf = ctx.ensureActiveAccount()

  const [isSmartVaultDrawerOpen, setSmartVaultDrawerOpen] = React.useState(false)
  const [isSmartVaultInfoDialogOpen, setSmartVaultInfoDialogOpen] = React.useState(false)

  const iconSize = 50

  const smartVaultActive = conf.isSmart
  const colorPrimaryOrDisabled = smartVaultActive ? theme.palette.primary.main : theme.palette.text.disabled
  const actualAktioAmount = ctx.getAccountByTypeIfExists('aktio')?.totalAmount || 0
  const hasNbAktioStacked = convert('aktio', 'euro', actualAktioAmount) > generalConfig.vaults.nbEuroOfAktioToStackForPremium
  if (hasNbAktioStacked && !conf.isPremium) ctx.editActiveAccount({ isPremium: true })

  return <Page dataTrkName='dailyInterestConfig' title={ctx.t.dailyInterest}>
    <Box px={2} >
      <Typography variant='h1' sx={{ my: 4 }}>{ctx.t.dailyInterestPageTitle}</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant='h3'>{ctx.t.howItWorks}</Typography>
        <Link data-trk='l-dailyInterestsLearnMoreLink-to:/vault/saving/dailyInterest/learnMore' style={{ textAlign: 'right' }} to='/vault/saving/dailyInterest/learnMore'>{ctx.t.learnMore}</Link>
      </Box>
      <SimpleVideoPlayer videoUrl={generalConfig.vaults.interestVideoUrl} />

      <Paper sx={{ my: 2, p: 2 }}>
        <Link data-trk='l-dailyInterestSimulateLink-to:/vault/saving/dailyInterest/simulate' to='/vault/saving/dailyInterest/simulate'>
          <Box sx={{ p: 2, display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }} >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography fontWeight={'bold'}>{ctx.t.wantToKnowHowMuchYouCanEarn}</Typography>
              <Typography variant='primary'>{ctx.t.simulateYourEarnings}</Typography>
            </div>
            <ArrowRightRoundedIcon sx={{ fontSize: 40, color: theme.palette.secondary.main }} />
          </Box>
        </Link>
      </Paper>

      <Typography variant='h3' sx={{ my: 3 }}>{ctx.t.dailyInterest}</Typography>

      <Paper sx={{ mb: 2, p: 2, display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box className='center' style={{ flex: `0 0 ${iconSize}px`, height: iconSize, width: iconSize, borderRadius: '50%', backgroundColor: theme.palette.primary.light, marginRight: 12 }}>
          <ParIcon fill={theme.palette.primary.main} />
        </Box>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant='primary'>{ctx.t.activateSmartVault}</Typography>
          <Typography fontWeight={'bold'}>{ctx.t.dailyInterestUpTo}*</Typography>
        </div>
        <Switch data-trk={`a-activateSmartVaultSwitch-checked:${smartVaultActive}`} checked={smartVaultActive} onChange={e => smartVaultActive ? ctx.editActiveAccount({ isSmart: false }) : setSmartVaultDrawerOpen(true)} />
      </Paper>


      <Typography variant='h3' sx={{ my: 3 }}>{ctx.t.dontMissOut}</Typography>

      <Link data-trk='l-premiumActivateLink-to:/vault/premium/activate' to="/vault/premium/activate" style={{ pointerEvents: !smartVaultActive || conf.isPremium ? 'none' : undefined }}>
        <Paper sx={{ mb: 2, p: 2 }}>
          <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box className='center' style={{ flex: `0 0 ${iconSize}px`, height: iconSize, width: iconSize, borderRadius: '50%', backgroundColor: smartVaultActive ? theme.palette.primary.light : theme.palette.disabled.light, marginRight: 12 }}>
              <HorseIcon fill={colorPrimaryOrDisabled} />
            </Box>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography color={colorPrimaryOrDisabled} fontWeight='bold'>
                {conf.isPremium ? ctx.t.premiumVaultActive : ctx.t.upgradeToPremiumVault}
              </Typography>
              <Typography fontWeight={'bold'}>{ctx.t.earnUpToBoosted}</Typography>
            </div>
            {conf.isPremium ?
              <CheckRoundedIcon style={{ fontSize: 40, color: colorPrimaryOrDisabled }} /> :
              <ArrowRightRoundedIcon style={{ fontSize: 40, color: theme.palette.secondary.main }} />}
          </Box>
          <Typography fontSize={'small'} sx={{ mt: '12px' }}>{ctx.t.vaultBoostDescription}</Typography>
        </Paper>
      </Link>

      {smartVaultActive ?
        <FullWidthButton data-trk='a-validateBtn-action:validate' onClick={() => ctx.previous()}>{ctx.t.done}</FullWidthButton> :
        <FullWidthButton data-trk='a-cancelBtn-action:cancel' variant='outlined' onClick={() => ctx.previous()}>{ctx.t.doItLater}</FullWidthButton>
      }
      <Typography fontSize='small' textAlign='center' my={2}>* {ctx.t.annualInterestPaidDaily}</Typography>
    </Box>

    <BottomDrawer open={isSmartVaultDrawerOpen} setOpen={setSmartVaultDrawerOpen}    >
      <Box sx={{ p: 2 }}>
        <Typography fontSize='small'>{ctx.t.smartVaultDrawerDisclaimer}</Typography>
        <FullWidthButton data-trk='a-smartVaultCreateBtn' onClick={() => {
          ctx.editActiveAccount({ isSmart: true })
          setSmartVaultDrawerOpen(false)
          setSmartVaultInfoDialogOpen(true)
        }}>{ctx.t.iAgree}</FullWidthButton>
      </Box>
    </BottomDrawer>
    <SmartVaultDialog isOpen={isSmartVaultInfoDialogOpen} setFullScreenDialogOpen={setSmartVaultInfoDialogOpen} />
  </Page>
}

export default DailyInterestConfigPage

function SmartVaultDialog({ isOpen, setFullScreenDialogOpen }) {
  const theme = useTheme()
  const ctx = React.useContext(appCtx)

  const iconSize = 100

  return <FullScreenDialogInfo isOpen={isOpen} setOpen={setFullScreenDialogOpen} >
    <Box className='center' style={{ flex: `0 0 ${iconSize}px`, height: iconSize, width: iconSize, borderRadius: '50%', backgroundColor: theme.palette.primary.light, marginBottom: 48 }}>
      <ParIcon fill={theme.palette.primary.main} style={{ transform: 'scale(2)' }} />
    </Box>
    <Typography fontWeight='bold'>{ctx.t.smartVaultActivated}</Typography>
    <Typography variant='primary'>{ctx.t.dailyInterestUpTo}</Typography>
  </FullScreenDialogInfo>
}