import styleMixins from './style-mixins'

const trackerStyle = {
  '.tracker-highlight': {
    ...styleMixins.overlay,
    zIndex: 999,
    pointerEvents: 'none',
    color: 'white',
    border: '1px solid #dddddd',
  },
  '.tracker-data-btn': {
    width: 15,
    fontWeight: 700,
    fontSize: 11,
    lineHeight: '15px',
    textAlign: 'center',
    pointerEvents: 'auto',
    position: 'relative',
  },
  '#dev-mode-data-container > div': {
    paddingRight: 16,
    paddingLeft: 16,
  },
  '.tracker-data': {
    paddingTop: 16,
    position: 'absolute',
    backgroundColor: '#DDDDDD'
  },
  '#event-data-container': {
    maxHeight: 150,
    overflow: 'auto',
    whiteSpace: 'nowrap',
    fontFamily: 'monospace',
  }
}

const colorByTypes = {
  c: '#026FCD', // container
  l: '#E4A44A', // link
  a: '#CE4F4B', // action
  i: '#ECD650', // input
  p: '#57af57', // page
}

let i = 0
for (const [typeLetter, color] of Object.entries(colorByTypes)) {
  trackerStyle[`.tracker-type-${typeLetter}.tracker-highlight`] = {
    borderColor: color
  }
  trackerStyle[`.tracker-type-${typeLetter} .tracker-data-btn`] = {
    left: i++ * 7,
    backgroundColor: color,
  }
}

export default trackerStyle