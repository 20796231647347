

import { useLocation } from "react-router-dom"
const Page1 = () => {

  const location = useLocation()

  return <div data-trk='c-page1Fake'>
    PAGE 1
  </div>
}

export default Page1
