import { history } from 'core/navigation-helper'
import $ from 'core/femto'
import trackerConfig from './tracker-config'
import { visibilityListener } from './app-listeners'
import { componentConfigs, ComponentConfigType } from './components-configs'
import { extractDataTrkPropValue } from './dom-helper'



setInterval(() => {
  try {
    for (const componentConfig of Object.values(componentConfigs)) {
      if (componentConfig.isVisible) componentConfig.displayTime++
    }
    if (displayMode === 'displayTime') highlightDisplayTime()
    else if (['clicks', 'missedClicks', 'rageClicks'].includes(displayMode)) highlightInteraction(displayMode as InteractiveDisplayModes)
  } catch (err) {
    console.error(err)
  }
}, 1000)

//----------------------------------------
// INIT
//----------------------------------------
let isON = false

function analyseModeInit() {
  try {
    if (isON) return
    else isON = true

    history.listen(({ action }) => {
      if (action === 'PUSH') analyseAppArchitecture()
    })
    analyseAppArchitecture()
    document.addEventListener('click', () => analyseAppArchitecture()) // click shall in most cases retrigger a react render thus an analyse app architecture. If you worry for perf, check async analyseAppArchitecture functioning 
  } catch (err) {
    console.error(err)
  }
}

setTimeout(analyseModeInit, 800)

//----------------------------------------
// DISPLAY MODES
//----------------------------------------
let displayMode: DisplayModes = 'none'
type InteractiveDisplayModes = 'clicks' | 'missedClicks' | 'rageClicks'
type DisplayModes = 'architecture' | 'displayTime' | InteractiveDisplayModes | 'none'

const toggleDisplayMode = (mode: DisplayModes) => {
  displayMode = mode
}

//----------------------------------------
// ARCHITECTURE ANALYSER
//----------------------------------------
let architectureTo
async function analyseAppArchitecture() {
  clearTimeout(architectureTo) // prevent too much renderings
  architectureTo = setTimeout(() => requestAnimationFrame(() => {
    try {
      if (isON) {
        const allTrackedElements = document.querySelectorAll('[data-trk]')
        allTrackedElements.forEach(elm => extractDataTrkPropValue(elm)) // build component description

        visibilityListener()

        if (displayMode === 'architecture') highlightAppArchitecture()
        else if (displayMode === 'none') $('.tracker-highlight').remove()
      }
    } catch (err) {
      console.error(err)
    }
  }))
}


//----------------------------------------
// HIGHLIGHT APP ARCHITECTURE
//----------------------------------------
function highlightAppArchitecture() {
  $('.tracker-highlight').remove()

  Object.values(componentConfigs).forEach(compoConfig => {
    const elm = compoConfig.elm
    const wrapperElm = $(`<div class='tracker-highlight tracker-type-${compoConfig.type}'></div>`)
    const hoverElm = $(`<div class='tracker-data-btn'>${compoConfig.type.toUpperCase()}</div>`)
    wrapperElm.prepend(hoverElm)
    $(elm).prepend(wrapperElm)
    hoverElm.on('mouseenter', () => {
      $('#tracker-data-container').html(`
        <div class='tracker-data'>
          Id: <b>${compoConfig.id}</b><br />
          Addr: <b>${compoConfig.addr}</b><br />
          DisplayTime: <b>${compoConfig.displayTime}</b><br/>
          NbClicks: <b>${compoConfig.clicks}</b> Rage: <b>${compoConfig.rageClicks}</b> Missed: <b>${compoConfig.missedClicks}</b>
        </div>
        `)
    }).on('mouseleave', () => $('#tracker-data-container').html(''))
  })
}

//----------------------------------------
// HEATMAP MODE
//----------------------------------------
function heatMap(computeRedBlueDescriptionValues: (compoConfig: ComponentConfigType) => ({ r: number, b: number, description: string })) {

  $('.tracker-highlight').remove()

  Object.values(componentConfigs).forEach(compoConfig => {
    const elm = compoConfig.elm
    const { r, b, description } = computeRedBlueDescriptionValues(compoConfig)
    const wrapperElm = $(`<div class='tracker-highlight tracker-heatmap-elm' style='background-color:rgba(${r},0,${b},${trackerConfig.heatMapOpacity})'></div>`)
    $(elm).prepend(wrapperElm)
    $(wrapperElm).on('mouseenter', () => $('#tracker-data-container').html(`<div class='tracker-data'>${description}</div>`))
    $(wrapperElm).on('mouseleave', () => $('#tracker-data-container').html('<div></div>'))
  })
}

function highlightDisplayTime() {
  const maxValue = Math.max(...Object.values(componentConfigs).map(tc => tc.displayTime))
  const colorFactorRed = 255 / maxValue
  heatMap(compoConfig => {
    const r = colorFactorRed * compoConfig.displayTime
    const b = 255 - r
    const description = `
    Id: <b>${compoConfig.id}</b><br />
    Addr: <b>${compoConfig.addr}</b><br />
    Visible: <b>${compoConfig.isVisible}</b>
    DisplayTime: <b>${compoConfig.displayTime}</b>
  `
    return { r, b, description }
  })
}

function highlightInteraction(mode: InteractiveDisplayModes) {
  const maxValue = Math.max(...Object.values(componentConfigs).map(tc => tc[mode]))
  const colorFactorRed = 255 / maxValue
  heatMap(compoConfig => {
    const r = colorFactorRed * compoConfig[mode]
    const b = 255 - r
    const description = `
    Id: <b>${compoConfig.id}</b><br />
    Addr: <b>${compoConfig.addr}</b><br />
    NB ${mode}: <b>${compoConfig[mode]}</b>
  `
    return { r, b, description }
  })
}

export {
  toggleDisplayMode,
}