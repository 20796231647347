import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import { appCtx } from '../01_shared/appContext'
import Page from '../01_shared/Page'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined'
import FullWidthButton from '../01_shared/FullWidthButton'
import Paper from '@mui/material/Paper'
import Chip from '@mui/material/Chip'
import { form2json } from '../01_shared/form-2-json.svc'
import TextField from '@mui/material/TextField'
import VaultImage from './VaultImage'
import generalConfig from '../01_shared/general-config'
import BottomDrawer from 'src/01_shared/BottomDrawer'
import LandscapeRoundedIcon from '@mui/icons-material/LandscapeRounded'
import RoundIcon from 'src/01_shared/RoundIcon'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'

import { forI } from 'topkat-utils'

const NewVaultEditTitleAndColorPage = () => {
  const theme = useTheme()
  const ctx = React.useContext(appCtx)
  const conf = ctx.ensureActiveAccount()

  const [vaultColor, setVaultColor] = React.useState(conf.color)
  const [vaultTitle, setVaultTitle] = React.useState(conf.name)
  const [vaultBgColor, setVaultBgColor] = React.useState(conf.backgroundColor)
  const [imageDrawerOpen, setImageDrawerOpen] = React.useState(false)

  const hasDefaultTitle = vaultTitle === ctx.t.newVaultDefaultTitle
  const vaultImgBoxStyle = { position: 'relative', borderBottomLeftRadius: 25, borderBottomRightRadius: 25, backgroundColor: theme.palette.bgDarker.main }
  const pictureIconStyle = { color: theme.palette.text.secondary, p: 1, pb: 0, position: 'absolute', right: 24, bottom: 24, background: theme.palette.background.paper, borderRadius: 1 }

  const handleSubmit = e => {
    const formJSON: any = form2json(e, e.target.parentElement) // https://www.learnwithjason.dev/blog/get-form-values-as-json
    if (formJSON) ctx.editActiveAccount(formJSON)
    ctx.previous()
  }

  return <Page dataTrkName='newVaultEditTitleAndColor' title={ctx.t.buildYourVault} titleAbsolute>
    <Box p={2} mb={2} sx={vaultImgBoxStyle}>
      <div className='center' style={{ height: 300 }}>
        <VaultImage account={{ ...conf, color: vaultColor, backgroundColor: vaultBgColor, }} />
      </div>
      <Box sx={pictureIconStyle}>
        <CameraAltOutlinedIcon onClick={() => setImageDrawerOpen(true)} />
      </Box>
    </Box>
    <Box p={2} component='form'>
      {/* FORM */}
      <Paper sx={{ mb: 2, p: 2 }} >
        <Typography variant='h3'>{ctx.t.vaultName}</Typography>

        <TextField className='input-bold' sx={{ my: 2, fontWeight: 700 }} variant="standard" name='name' focused value={hasDefaultTitle ? '' : vaultTitle} placeholder={hasDefaultTitle || vaultTitle === '' ? vaultTitle : ''} fullWidth onChange={e => setVaultTitle(e.target.value)} inputProps={{ autoComplete: 'off' }} />

        <TagPicker setVaultTitle={setVaultTitle} />
      </Paper>

      <Box py={2}>
        <Typography variant='h3' sx={{ mb: 1 }}>{ctx.t.vaultColor}</Typography>
        <input name='color' type='hidden' value={vaultColor} />
        <InlineColorPicker data-trk={`a-pickVaultColor-value:${vaultColor}`} activeColor={vaultColor} setActiveColor={setVaultColor} colors={generalConfig.vaults.colors} />
      </Box>

      <Box py={2}>
        <Typography variant='h3' sx={{ mb: 1 }}>{ctx.t.vaultBackgroundColor}</Typography>
        <input name='backgroundColor' type='hidden' value={vaultBgColor} />
        <InlineColorPicker data-trk={`a-pickVaultBackgroundColor-value:${vaultBgColor}`} activeColor={vaultBgColor} setActiveColor={setVaultBgColor} colors={generalConfig.vaults.backgroundColors} />
      </Box>

      <FullWidthButton data-trk='a-validateBtn-action:validate' type="submit" onClick={handleSubmit}>{ctx.t.done}</FullWidthButton>
    </Box>

    <BottomDrawer open={imageDrawerOpen} setOpen={setImageDrawerOpen} >
      <Box sx={{ py: 2, textAlign: 'center', }}>
        <RoundIcon Icon={LandscapeRoundedIcon} sx={{ margin: 'auto', my: 2 }} iconSize={80} />
        <Stack divider={<Divider flexItem />} spacing={2} sx={{ mb: 2, mt: 4 }}>
          <Typography fontWeight={700}>{ctx.t.changeCoverImage}</Typography>
          <Typography variant='primary' onClick={() => alert(ctx.t.workInProgress)}>{ctx.t.personalizedVaultAnimation}</Typography>
          <Typography variant='disabled'>{ctx.t.browseOurSelection}</Typography>
          <Typography data-trk='a-changeVaultCoverCancelBtn' onClick={() => setImageDrawerOpen(false)}>{ctx.t.doItLater}</Typography>
        </Stack>
      </Box>
    </BottomDrawer>
  </Page>
}

export default NewVaultEditTitleAndColorPage




type InlineColorPickerProps = { colors: string[], activeColor: string, setActiveColor: (string) => void }
function InlineColorPicker({ colors, activeColor, setActiveColor }: InlineColorPickerProps) {
  const theme = useTheme()

  const badgeSize = (100 / colors.length - 6) + 'vw'

  return <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    {colors.map(color => {
      const colorBadgeStyle = {
        width: badgeSize,
        height: badgeSize,
        borderRadius: '50%',
        border: color === activeColor ? `2px solid ${theme.palette.primary.main}` : '0 solid transparent',
        backgroundColor: color
      }
      return <span data-trk='ignore' key={color} style={colorBadgeStyle} onClick={() => setActiveColor(color)}></span>
    })}
  </div>
}



function TagPicker({ setVaultTitle }) {
  const ctx = React.useContext(appCtx)

  const tags = Object.keys(ctx.t.vaultTags)
  const newTags = [...tags]
  const rows = [5, 4]

  return <>{rows.map((nbElemPerRow, i1) => <Box key={i1} sx={{ display: 'flex', mb: 1, justifyContent: 'space-between' }}>{forI(nbElemPerRow, i => {
    const newTag = newTags.shift()
    if (!newTag) return null
    const clickHandler = () => setVaultTitle(ctx.t.vaultNamesSuggestions[newTag])
    return <Chip data-trk={`a-vaultNameSuggest-name:${newTag}`} key={i} label={ctx.t.vaultTags[newTag]} onClick={clickHandler} />
  })}</Box>)}
  </>
}


