const primary = `#20ADEA`

const themeVarz = {
  primaryBlue: primary,
  primaryLight: `#D2EFFB`,
  darkNavyBlue: `#42597A`,
  lightGrey: `#C0D4E3`,
  veryLightGrey: `#F6F8FA`,
  defaultFontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
  transitionTime: .6,
  paletteChart: [
    '#8840C9',
    primary,
  ],
  gradientChart: [
    [{ offset: 0, color: '#B72AD5', opacity: 1 }, { offset: 100, color: '#FFFFFF', opacity: 1 },],
    [{ offset: 0, color: primary, opacity: 1 }, { offset: 100, color: '#FFFFFF', opacity: 1 },]
  ]
}

export default themeVarz