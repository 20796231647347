
import { componentConfigs, ComponentConfigType } from './components-configs'
import $ from '../00_core/femto'

type getNextParentReturnType =
  { isFound: true, type: string, id: string, addr: string, componentConfig: ComponentConfigType } |
  { isFound: false, type?: undefined, id?: undefined, addr?: undefined, componentConfig?: ComponentConfigType }

export function getNextParentElmTrkProps(elm: Element | null): getNextParentReturnType {
  if (!elm) return { isFound: false }
  const trackerProps = extractDataTrkPropValue(elm)
  if (trackerProps.isFound) return trackerProps
  else return getNextParentElmTrkProps(elm.parentElement)
}

export function getAllParentElmIds(elm: Element | null, findAll = true, parents: string[] = []) {
  const parent = elm && elm.parentElement
  if (parent) {
    if (findAll) getAllParentElmIds(parent, findAll, parents)
    const { id } = extractDataTrkPropValue(parent)
    if (id) parents.push(id)
  }
  return parents
}

export function extractDataTrkPropValue(elm: Element): getNextParentReturnType {
  let parents
  const dataTrkProp = elm.getAttributeNode('data-trk')?.value

  if (dataTrkProp && dataTrkProp !== 'ignore') {
    const [, type, id, paramsStr] = dataTrkProp.match(/^([a-z])-([^-]+)-?(.*)?$/) || []

    $(elm).attr('data-trk-id', id)

    let addr = elm.getAttributeNode('data-trk-addr')?.value

    if (typeof addr === 'undefined') {
      parents = getAllParentElmIds(elm)
      addr = [...parents, id].join('/')

      $(elm).attr('data-trk-addr', addr)
    }

    let componentConfig = componentConfigs[addr || '']

    if (typeof componentConfig === 'undefined') {
      componentConfig = componentConfigs[addr] = {
        id,
        type,
        addr,
        url: window.location.pathname,
        parents: parents || getAllParentElmIds(elm),
        isMultiple: false,
        elm: elm,
        displayTime: 0,
        clicks: 0,
        rageClicks: 0,
        missedClicks: 0,
        rageClickTracker: { nbClick: 0 },
        inputEditHistory: [],
      }
    }

    componentConfig.elm = elm

    if (paramsStr) {
      componentConfig.params = paramsStr.split('-').reduce((obj, lineStr) => {
        const [key, val] = lineStr.split(':')
        obj[key] = val
        return obj
      }, {})
    }

    return { type, id, addr, isFound: true, componentConfig }
  } else return { isFound: false }
}