// import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import * as t from '../01_shared/types'

type RoundIconProps = { Icon: any, iconSize?: number, iconColor?: string, bgColor?: string, style?: t.ObjectGeneric, [k: string]: any }
const RoundIcon = ({ Icon, iconSize = 40, iconColor, bgColor, style, ...props }: RoundIconProps) => {
  const theme = useTheme()

  const innerIconSize = iconSize * 0.75

  return <Box className='center' style={{
    flex: `0 0 ${iconSize}px`,
    height: iconSize,
    width: iconSize,
    borderRadius: '50%',
    backgroundColor: bgColor || theme.palette.primary.light,
    color: iconColor || theme.palette.primary.main, // works also for react material icon
    ...style,
  }} {...props}>
    <Icon
      style={{ fontSize: innerIconSize, height: innerIconSize * 0.70, width: innerIconSize * 0.70 }}
      fill={iconColor || theme.palette.primary.main} // for custom icons
    />
  </Box>
}

export default RoundIcon