import * as React from 'react'
import { appCtx } from './appContext'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'

// data-trk ignoreFile
type SelectAccountProps = { value: any, onChange: (val: any) => any, [k: string]: any }

function SelectUserAccount({ value, onChange, ...props }: SelectAccountProps) {

  const ctx = React.useContext(appCtx)
  const accounts = ctx.user.accounts.map(acc => [acc.id, acc.name])

  return <FormControl sx={{ fontWeight: 700 }} >
    <TextField
      select
      variant='standard'
      size='small'
      value={value}
      {...props}
      onChange={e => onChange(e.target.value)}
      SelectProps={{ /*disableUnderline: true,*/ native: true, sx: { fontWeight: 700, p: 0, '& select': { paddingBottom: 0 }, } }}
    >
      {accounts.map(([value, label]) => <option key={value} value={value}>{label}</option>)}
    </TextField>
  </FormControl>
}


export default SelectUserAccount