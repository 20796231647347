import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import BottomDrawer from '../01_shared/BottomDrawer'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { appCtx } from '../01_shared/appContext'
import ActionCard from '../01_shared/ActionCard'
import { ReactComponent as BitcoinIcon } from '../01_shared/assets/bitcoin-icon.svg'
import { ReactComponent as VaultIcon } from '../01_shared/assets/vault-icon.svg'

type NewVaultDrawerProps = { open: boolean, setOpen: (open: boolean) => void }
const NewVaultDrawer = ({ open, setOpen }: NewVaultDrawerProps) => {
  const theme = useTheme()
  const ctx = React.useContext(appCtx)

  return <BottomDrawer data-trk='c-newVaultDrawer' open={open} setOpen={setOpen}    >
    <Box sx={{ p: 2 }}>
      <div style={{ textAlign: 'center', marginBottom: 24 }}>
        <Typography fontWeight={700}>{ctx.t.createNewWallet}</Typography>
        <Typography>{ctx.t.chooseAccountType}</Typography>
      </div>

      <ActionCard
        data-trk-name='createNewVaultSavingBtn'
        leftIcon={<VaultIcon fill={theme.palette.primary.main} />}
        title={ctx.t.NewSavingVaultPageTitle}
        subtitle={<Typography fontSize={'small'}>{ctx.t.NewSavingVaultPageDescription}</Typography>}
        cardLink='vault/new/saving'
        cardAction={() => {
          ctx.activeAccount = null
        }}
      />
      <ActionCard
        data-trk-name='createNewVaultCryptoBtn'
        leftIcon={<BitcoinIcon fill={theme.palette.primary.main} />}
        title={ctx.t.newCryptoVaultTitle}
        subtitle={<Typography fontSize={'small'}>{ctx.t.newCryptoVaultDescription}</Typography>}
        cardLink={'vault/new/crypto'}
      />

    </Box>
  </BottomDrawer>
};

export default NewVaultDrawer