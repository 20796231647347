import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import { appCtx } from '../01_shared/appContext'
import Page from '../01_shared/Page'
import { ReactComponent as EuroParImg } from '../01_shared/assets/1-euro-1-par.svg'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { ReactComponent as CrownIcon } from '../01_shared/assets/crown-icon.svg'
import Box from '@mui/material/Box'

const DailyInterestLearnMorePage = () => {
  // const theme = useTheme()
  const ctx = React.useContext(appCtx)

  return <Page dataTrkName='dailyInterestLearnMore' title={ctx.t.dailyInterestLearnMore.pageTitle} p={2}>
    <Typography variant='h2' sx={{ mb: 3 }}>{ctx.t.dailyInterestLearnMore.pageSubtitle}</Typography>

    <Paper sx={{ p: 2, mb: 4 }}>
      <TimeLine>
        <TimeLineItem
          title={ctx.t.dailyInterestLearnMore.step1Title}
          description={ctx.t.dailyInterestLearnMore.step1Description}
          icon={1}
        />

        <TimeLineItem
          title={ctx.t.dailyInterestLearnMore.step2Title}
          description={ctx.t.dailyInterestLearnMore.step2Description}
          icon={2}
        />

        <TimeLineItem
          title={ctx.t.dailyInterestLearnMore.step3Title}
          description={ctx.t.dailyInterestLearnMore.step3Description}
          icon={<span>3<CrownIcon style={{ position: 'absolute', top: -10, left: -3 }} /></span>}
        />
      </TimeLine>
    </Paper>

    <div className='center'>
      <EuroParImg />
    </div>

    <Paper sx={{ p: 2, mt: 4 }}>
      <Typography fontWeight='bold' sx={{ mb: 2 }}>{ctx.t.dailyInterestLearnMore.section2Title}</Typography>
      <Typography fontSize='small'>{ctx.t.dailyInterestLearnMore.section2Description}</Typography>
    </Paper>
  </Page>
}

export default DailyInterestLearnMorePage

type TimeLinePropsType = { children?: any, linkColor?: string, circleColor?: string }
function TimeLine({ children, linkColor, circleColor }: TimeLinePropsType) {
  const theme = useTheme()

  const circleColorStr: string = circleColor || theme.palette.bgDarkest.main
  const linkColorStr: string = linkColor || theme.palette.bgDarkest.main

  const wrapperStyle = {
    '& .firstTr': { zIndex: 1, position: 'relative' },
    '& .secondTr': { zIndex: 0, position: 'relative' },
    '& .timelineLinkWrapper': { position: 'relative' },
    '& .timelineLink': {
      backgroundColor: linkColorStr,
      width: 2, height: 'calc(100% + 32px)', position: 'absolute', marginLeft: 'calc(50% - 2px)', top: -24, zIndex: 0
    },
    '& .secondTr:last-child .timelineLink': { display: 'none' },
    '& .title': { fontWeight: 700, paddingLeft: 2, fontSize: '14px' },
    '& .description': { paddingLeft: 2, fontSize: '12px' },
    '& .timelineIcon': {
      backgroundColor: circleColorStr,
      borderRadius: '50%',
      paddingTop: '2px',
      width: 26,
      height: 26,
      fontWeight: 700,
      color: 'white',
    }
  }
  return <Box sx={wrapperStyle}>
    <table>
      <tbody>{children}</tbody>
    </table>
  </Box>
}

function TimeLineItem({ icon, title, description }) {
  return <>
    <tr className='firstTr'>
      <td className='timelineIcon center'>{icon}</td><td className='title'>{title}</td>
    </tr>
    <tr className='secondTr'>
      <td className='timelineLinkWrapper'><div className='timelineLink'></div></td><td className='description'>{description}</td>
    </tr>
  </>
}