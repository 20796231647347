import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import Box from '@mui/material/Box'

type SimpleVideoPlayerPropsType = { videoUrl: string, autoPlay?: boolean }
const SimpleVideoPlayer = ({ videoUrl, autoPlay = false }: SimpleVideoPlayerPropsType) => {
  const theme = useTheme()

  const [isPlaying, setIsPlaying] = React.useState(autoPlay ? true : false)
  const [isVideoFullScreen, setVideoFullScreen] = React.useState(false)

  const fullScreenStyle = {
    position: 'fixed',
    backgroundColor: "#000000",
    borderRadius: 0,
  }

  return <div
    data-trk='c-simpleVideoPlayer'
    onClick={() => setVideoFullScreen(!isVideoFullScreen)}
    style={{ paddingTop: '56%' }} // 16:9
  >
    <Card
      className='center'
      style={{
        position: 'absolute',
        top: 0, left: 0,
        width: '100%',
        height: '100%',
        transition: 'all .5s',
        zIndex: 1050,
        ...(isVideoFullScreen ? fullScreenStyle : {})
      } as any}
      data-trk={`a-playPauseBtn-isPlaying:${isPlaying}`}
      onClick={(e: any) => {
        const [videoElm] = e.target.parentElement.parentElement.parentElement.querySelectorAll('.videoPlayerSimple')
        if (videoElm.paused) videoElm.play()
        else videoElm.pause()
        setIsPlaying(!isPlaying)
      }}>
      <CardMedia
        className='videoPlayerSimple'
        component='video'
        image={videoUrl}
        autoPlay={autoPlay}
      />
      {/* OVERLAY */}
      <Box className='center' sx={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, display: isPlaying ? 'none' : 'flex' }}>
        <PlayArrowRoundedIcon sx={{ fontSize: 40, color: '#fff', backgroundColor: theme.palette.primary.main, borderRadius: '50%' }} />
      </Box>
    </Card>
  </div>
}

export default SimpleVideoPlayer