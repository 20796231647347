// import * as React from 'react'
// import { useTheme } from '@mui/material/styles'
// import { appCtx } from '../01_shared/appContext'
import Box from '@mui/material/Box'
import * as t from '../01_shared/types'

// data-trk ignoreFile
type VaultImagePropsType = { account: t.UserAccountType }
const VaultImage = ({ account }: VaultImagePropsType) => {

  const mainColor = account.color
  const secondaryColor = account.backgroundColor
  const Icon = account.icon
  const icon = account.type === 'vault' ? <VaultIcon mainColor={mainColor} /> : <Icon fill={mainColor} />
  const iconStyle = account.iconStyle || {}

  return <Box data-trk={`c-vaultImageBig`} className='center' sx={{
    borderRadius: '50%',
    backgroundColor: secondaryColor,
    height: 100,
    width: 100,
    '& path': { fill: mainColor },
    '& svg': {},
    ...iconStyle
  }}>
    {icon}
  </Box>
}

export default VaultImage


function VaultIcon({ mainColor }) {
  return <svg width="112" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.8">
      <path d="M75.9915 46.1372V46.7927C75.9915 53.1763 75.9944 59.5608 76.0001 65.9459C76.0001 66.3785 75.8924 66.6003 75.4616 66.7787C69.5913 69.2477 63.7267 71.7303 57.8679 74.2267C57.7075 74.2949 57.5448 74.3522 57.3229 74.4365C57.3122 74.1943 57.2949 74.0039 57.2949 73.8157C57.2949 67.414 57.2949 61.0116 57.2949 54.6084C57.2949 54.2688 57.31 54.0287 57.7075 53.861C63.6926 51.3408 69.6721 48.8077 75.6457 46.2616C75.7255 46.227 75.8073 46.2021 75.9915 46.1372Z" fill={mainColor} />
      <path d="M48.2918 49.3199C47.3224 48.9954 46.3885 48.6806 45.4622 48.3648C40.9433 46.8224 36.4241 45.2807 31.9045 43.7398C31.5814 43.6316 31.451 43.4954 31.4974 43.1309C32.2822 36.9339 35.2227 32.0483 40.3189 28.4739C41.5609 27.6086 42.9654 27.0224 44.4895 26.8742C45.1153 26.8136 45.8015 26.8255 46.3917 27.0181C50.9802 28.5183 55.5537 30.0704 60.1303 31.6073C60.2305 31.6409 60.3275 31.6863 60.5407 31.7728C56.9249 33.2871 54.2191 35.7748 52.0789 38.9115C49.9387 42.0482 48.6117 45.4563 48.2918 49.3199Z" fill={mainColor} />
      <path d="M31.483 45.7236C32.3781 46.0265 33.2128 46.2872 34.025 46.6073C34.1607 46.6603 34.2641 46.9718 34.2652 47.1643C34.2792 50.7618 34.2749 54.3603 34.2749 57.9588V59.5932L45.1441 63.2706V50.3875C46.1835 50.7445 47.1464 51.0582 48.09 51.4227C48.1977 51.4659 48.258 51.7828 48.2591 51.9732C48.2688 55.1207 48.2655 58.2693 48.2655 61.4178V71.8554C47.5299 71.6045 46.8653 71.3817 46.1942 71.1491C41.4312 69.4849 36.6679 67.8217 31.9041 66.1596C31.5885 66.0515 31.4561 65.9206 31.4561 65.5626C31.4675 59.0686 31.4708 52.5742 31.4658 46.0795C31.4658 45.9908 31.4744 45.9021 31.483 45.7236Z" fill={mainColor} />
      <path d="M57.2637 51.8576C57.3691 50.3496 57.6917 48.865 58.2212 47.45C59.7217 43.4145 62.0536 39.9868 65.5909 37.4721C67.0374 36.4446 68.6197 35.6961 70.4163 35.5252C72.213 35.3543 73.6391 36.0184 74.601 37.5803C75.5122 39.0577 75.8202 40.7169 75.9495 42.4118C76.0658 43.9261 76.0389 43.9174 74.6483 44.5058C69.0796 46.8637 63.512 49.2235 57.9455 51.585C57.7355 51.6672 57.5233 51.7516 57.2637 51.8576Z" fill={mainColor} />
      <path d="M67.4647 34.081C60.1672 37.6915 56.3477 43.7139 55.1532 51.6702L50.29 50.0099C50.3335 48.4771 50.6137 46.9603 51.1205 45.5137C52.5757 41.2954 54.9572 37.7369 58.6022 35.114C60.0724 34.0561 61.6913 33.285 63.5289 33.1519C64.097 33.1222 64.6662 33.1824 65.2156 33.3304C65.9836 33.5262 66.7247 33.8279 67.4647 34.081Z" fill={mainColor} />
      <path d="M50.311 52.1528C51.5927 52.5855 52.8411 53.0116 54.0884 53.441C55.3023 53.8585 55.3013 53.8618 55.3013 55.1489V74.2783C55.1559 74.2531 55.0121 74.2199 54.8704 74.1788C53.4702 73.6899 52.0699 73.1913 50.6697 72.7111C50.4036 72.6202 50.2636 72.5229 50.2647 72.1984C50.2754 65.6193 50.2787 59.0405 50.2743 52.4622C50.2797 52.3778 50.2948 52.2934 50.311 52.1528Z" fill={mainColor} />
      <path d="M29.4107 42.8725L25.0117 41.3756C25.0444 40.0798 25.262 38.7955 25.658 37.5618C27.1304 32.9195 29.6789 29.0398 33.7568 26.2914C35.2971 25.253 36.9979 24.537 38.8893 24.669C39.8479 24.7371 40.785 25.107 41.7975 25.3558C34.4386 28.9879 30.6267 34.9778 29.4107 42.8725Z" fill={mainColor} />
      <path d="M24.9989 43.5054L28.493 44.6951C29.4624 45.0261 29.4624 45.0261 29.4624 46.0472V65.2804C28.94 65.1052 28.493 64.9559 28.0428 64.8024C27.2145 64.5168 26.3959 64.1988 25.5568 63.9479C25.126 63.8181 24.9892 63.6158 24.9902 63.1594C25.0075 56.9899 25.0118 50.821 25.0032 44.653L24.9989 43.5054Z" fill={mainColor} />
      <path d="M43.0861 60.4108C42.8954 60.3599 42.7091 60.3221 42.5303 60.2615C40.5861 59.605 38.6387 58.9387 36.6891 58.2897C36.4241 58.201 36.2841 58.0994 36.2852 57.7738C36.2992 54.4132 36.2938 51.0527 36.2959 47.6921C36.2959 47.6088 36.3142 47.5244 36.3315 47.3784C37.3623 47.7278 38.3575 48.0642 39.3474 48.4027C40.4805 48.7888 41.6169 49.1663 42.7403 49.5784C42.9073 49.639 43.127 49.8705 43.1281 50.0251C43.1485 53.4203 43.141 56.8177 43.1378 60.2139C43.1276 60.2812 43.1102 60.3472 43.0861 60.4108ZM40.5926 57.0264C40.6136 56.9624 40.6284 56.8965 40.6367 56.8296C40.6367 56.0573 40.6162 55.2829 40.6604 54.5117C40.6814 54.2244 40.7948 53.9519 40.9835 53.7351C41.7267 52.9358 41.7494 51.7709 40.99 51.0191C40.8151 50.8444 40.6076 50.7059 40.3793 50.6117C40.1511 50.5175 39.9066 50.4694 39.6598 50.4701C39.4131 50.4708 39.1688 50.5203 38.9411 50.6158C38.7134 50.7113 38.5067 50.8509 38.3328 51.0267C37.5788 51.7838 37.6036 52.9444 38.35 53.7426C38.5175 53.9325 38.6172 54.1731 38.6333 54.4262C38.6667 55.2829 38.6473 56.1406 38.6473 57.0221L40.5926 57.0264Z" fill={mainColor} />
    </g>
  </svg>
}