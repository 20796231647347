
import React from 'react'
import $ from './00_core/index'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Button from '@mui/material/Button'
import Loader from './01_shared/Loader';
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import ReactCountryFlag from "react-country-flag"
import { form2json } from './01_shared/form-2-json.svc'
import { appCtx } from './01_shared/appContext'

const LoginPage = ({ updateUser }) => {
  const ctx = React.useContext(appCtx)

  const [isLoading, setIsLoading] = React.useState(false)

  const handleSubmitCallback = React.useCallback(ctx => e => {
    const formJSON = form2json(e, e.target.parentElement.parentElement) // https://www.learnwithjason.dev/blog/get-form-values-as-json
    if (formJSON && formJSON.password === 'akt2022') {
      setIsLoading(true)
      formJSON.lang = ctx.user.lang
      updateUser({ ...ctx.user, ...formJSON })
      // $.api.user.create(formJSON).then(user => updateUser(user))
    }
    setIsLoading(false)
  }, [updateUser])

  const flagStyle = { fontSize: '2em', lineHeight: '2em' }
  const flagStyleActive = { fontSize: '3em', lineHeight: '3em' }

  return <Paper data-trk='c-loginForm' sx={{ margin: '50px auto 0', maxWidth: 300, width: '80%', padding: 3, minHeight: 250 }}>
    {isLoading ? <Loader /> : (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
          <ReactCountryFlag data-trk='a-gbCountryFlag' countryCode='GB' style={ctx.user.lang === 'en' ? flagStyleActive : flagStyle} onClick={() => ctx.changeLanguage('en')} />
          <ReactCountryFlag data-trk='a-frCountryFlag' countryCode='FR' style={ctx.user.lang === 'fr' ? flagStyleActive : flagStyle} onClick={() => ctx.changeLanguage('fr')} />
        </div>
        <Box component="form" mt={2} >
          <Typography variant='h2' sx={{ marginBottom: 2 }}>{ctx.t.enterYourDetails}</Typography>
          <FormControl sx={{ marginBottom: 4 }} fullWidth>
            {/* BIRTHDATE */}
            <TextField fullWidth label={ctx.t.birthDate} name={'birthDate'} inputProps={{ pattern: "\\d?\\d\\/\\d?\\d\\/\\d\\d\\d\\d" }} variant="standard" />
          </FormControl>
          <FormControl >
            <FormLabel>{ctx.t.gender}</FormLabel>
            <RadioGroup row>
              {/* GENDER */}
              <FormControlLabel value="female" control={<Radio required name='gender' />} label={ctx.t.female} />
              <FormControlLabel value="male" control={<Radio required name='gender' />} label={ctx.t.male} />
              <FormControlLabel value="other" control={<Radio required name='gender' />} label={ctx.t.other} />
            </RadioGroup>
          </FormControl>
          <FormControl sx={{ marginBottom: 4 }} fullWidth>
            {/* BIRTHDATE */}
            <TextField fullWidth label={ctx.t.password} name={'password'} variant="standard" inputProps={{ type: 'password' }} />
          </FormControl>
          <div>
            <Button data-trk='a-loginSubmit' variant="contained" fullWidth type="submit" onClick={handleSubmitCallback(ctx)}>{ctx.t.enter}</Button>
          </div>
        </Box>
      </div>
    )}
  </Paper>
};

export default LoginPage
