import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import { appCtx } from '../01_shared/appContext'
import Page from '../01_shared/Page'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import AmountInput from 'src/01_shared/AmountInput'
import GpsFixedRoundedIcon from '@mui/icons-material/GpsFixedRounded'
import DataUsageRoundedIcon from '@mui/icons-material/DataUsageRounded'
import Switch from '@mui/material/Switch'
import DatePicker from '@mui/lab/DatePicker'
import TextField from '@mui/material/TextField'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import FullWidthButton from 'src/01_shared/FullWidthButton'


const VaultSavingGoalPage = () => {
  const theme = useTheme()
  const ctx = React.useContext(appCtx)
  const conf = ctx.ensureActiveAccount()

  const [savingGoalActive, setSavingGoalActive] = React.useState(conf.savingGoalActive)
  const [deadlineActive, setDeadlineActive] = React.useState(conf.deadlineActive)

  return <Page dataTrkName='vaultSavingGoal' p={2} title={ctx.t.savingGoalPageTitle}>
    <Typography variant='h1' mb={3}>{ctx.t.savingGoalHeader}</Typography>


    <Paper sx={{ mb: 3 }}>
      <Box p={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <GpsFixedRoundedIcon sx={{ flex: '0 0 20px', marginRight: 1 }} />
        <Typography style={{ flex: '1 1 100%', fontWeight: 700 }}>{ctx.t.goalAmount}</Typography>
        <Switch style={{ flex: '0 0 40px' }} checked={savingGoalActive} onChange={e => setSavingGoalActive(e.target.checked)} />
      </Box>
      {/* DIVIDER */}
      <div style={{ borderBottom: `2px solid ${theme.palette.bgDarker.main}` }}></div>
      <Box p={2}>
        <AmountInput
          value={conf.savingGoal || 0}
          currency={ctx.user.currency.short}
          disabled={!savingGoalActive}
          onChange={val => ctx.editActiveAccount({ savingGoal: val })}
        />
      </Box>
    </Paper>


    <Paper>
      <Box p={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <DataUsageRoundedIcon sx={{ flex: '0 0 20px', marginRight: 1 }} />
        <Typography style={{ flex: '1 1 100%', fontWeight: 700 }}>{ctx.t.deadline}</Typography>
        <Switch style={{ flex: '0 0 40px' }} checked={deadlineActive} onChange={e => setDeadlineActive(e.target.checked)} />
      </Box>
      {/* DIVIDER */}
      <div style={{ borderBottom: `2px solid ${theme.palette.bgDarker.main}` }}></div>
      <Box p={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={conf.deadLine}
            disabled={!deadlineActive}
            minDate={new Date()}
            onChange={(newValue) => {
              if (newValue) ctx.editActiveAccount({ deadLine: newValue })
            }}
            renderInput={(params) => <TextField fullWidth variant='standard' inputProps={{ style: { fontWeight: 700 } }} {...params} />}
          />
        </LocalizationProvider>
      </Box>
    </Paper>

    <FullWidthButton data-trk='a-validateBtn-action:validate' onClick={() => {
      ctx.editActiveAccount({ savingGoalActive, deadlineActive })
      ctx.previous()
    }}>
      {ctx.t.confirm}
    </FullWidthButton>
    <FullWidthButton data-trk='a-cancelBtn-action:cancel' variant='outlined' onClick={ctx.previous}>
      {ctx.t.cancel}
    </FullWidthButton>
  </Page>
}



export default VaultSavingGoalPage