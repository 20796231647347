import * as React from 'react'
import { appCtx } from '../01_shared/appContext'
import Page from '../01_shared/Page'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import AmountInput from 'src/01_shared/AmountInput'
import SelectUserAccount from '../01_shared/SelectUserAccount'
import Select from '@mui/material/Select'
import * as t from '../01_shared/types'
import DatePicker from '@mui/lab/DatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import FullWidthButton from 'src/01_shared/FullWidthButton'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'
import generalConfig from '../01_shared/general-config'
import MenuItem from '@mui/material/MenuItem'
import FullScreenDialogInfo from 'src/01_shared/FullScreenDialogInfo'
import RoundIcon from 'src/01_shared/RoundIcon'
import StarRoundedIcon from '@mui/icons-material/StarRounded'

import { miniTemplater, dateSlash, differenceInDays } from 'topkat-utils'

const VaultRecurringTransferPage = () => {

  const ctx = React.useContext(appCtx)
  const conf = ctx.ensureActiveAccount()

  const [recurringActive, setRecurringActive] = React.useState(conf.recurringTransferActive)
  const [sourceAccount, setSourceAccount] = React.useState(ctx.getAccountByType('fiat'))
  const [dialogOpen, setDialogOpen] = React.useState(false)

  return <Page dataTrkName='vaultRecurringTransfer' title={ctx.t.recurringTransfer} p={2} sx={{ p: 2, '& input, & .MuiSelect-select': { fontWeight: 700 } }}>
    <Typography variant='h1' mb={3}>{ctx.t.savingGoalHeader}</Typography>

    <Paper sx={{ p: 2 }}>
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography style={{ flex: '1 1 100%', fontWeight: 700 }}>{ctx.t.recurringTransfer}</Typography>
        <Switch style={{ flex: '0 0 40px' }} checked={recurringActive} onChange={e => setRecurringActive(e.target.checked)} />
      </Box>

      <Box my={2}>
        <Typography component={'span'} >{ctx.t.from}&nbsp;</Typography>
        <SelectUserAccount disabled={!recurringActive} value={sourceAccount.id} onChange={accId => setSourceAccount(ctx.getAccountById(accId))} />
      </Box>

      <Box my={2}>
        <Typography>{ctx.t.amount}</Typography>
        <AmountInput value={conf.recurringTransferAmount} currency={sourceAccount.currency.short} disabled={!recurringActive} onChange={val => ctx.editActiveAccount({ recurringTransferAmount: val })} />
      </Box>

      <Box my={2}>
        <Typography>{ctx.t.frequency}</Typography>
        <Select
          fullWidth
          variant='standard'
          disabled={!recurringActive}
          value={conf.recurringTransferFrequency}
          onChange={e => ctx.editActiveAccount({ recurringTransferFrequency: (e.target.value as t.RecurringTransferFrequency) })}
        >
          {Object.keys(generalConfig.recurringTransferPeriods).map(recurringPeriod => {
            return <MenuItem key={recurringPeriod} value={recurringPeriod}>{ctx.t.frequencyPeriod[recurringPeriod]}</MenuItem>
          })}
        </Select>
      </Box>

      <Box my={2}>
        <Typography>{ctx.t.start}</Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={conf.deadLine}
            disabled={!recurringActive}
            minDate={new Date()}
            onChange={(newValue) => {
              if (newValue) ctx.editActiveAccount({ deadLine: newValue })
            }}
            renderInput={(params) => <TextField fullWidth variant='standard' inputProps={{ style: { fontWeight: 700 } }} {...params} />}
          />
        </LocalizationProvider>
      </Box>
    </Paper>
    {conf.deadlineActive ? <Typography fontSize='small' sx={{ pt: 2 }}>
      {(() => {
        // Regarding your goal settings, we advice you save {{saveAdvice}} {{period}} to reach your goal amount {{goalAmount}} on {{goalDate}}
        const nbDaysTotal = differenceInDays(new Date(), conf.deadLine as Date)
        const nbDaysForPeriod = generalConfig.recurringTransferPeriods[conf.recurringTransferFrequency]
        const nbPeriods = nbDaysTotal / nbDaysForPeriod
        return miniTemplater(ctx.t.recurringAdvice, {
          saveAdvice: Math.ceil(conf.savingGoal / nbPeriods) + ctx.user.currency.symbol,
          goalDate: dateSlash(conf.deadLine as Date),
          period: ctx.t.frequencyPeriod[conf.recurringTransferFrequency],
          goalAmount: conf.savingGoal + ctx.user.currency.symbol
        })
      })()}
    </Typography> : null}

    <FullWidthButton data-trk='a-validateBtn-action:validate' disabled={!recurringActive} onClick={() => {
      ctx.editActiveAccount({ recurringTransferActive: recurringActive, recurringTransferSourceAccountId: sourceAccount.id })
      setDialogOpen(true)
    }}>
      {ctx.t.confirm}
    </FullWidthButton>
    <FullWidthButton data-trk='a-cancelBtn-action:cancel' variant='outlined' onClick={ctx.previous}>
      {ctx.t.cancel}
    </FullWidthButton>

    <FullScreenDialogInfo isOpen={dialogOpen} setOpen={setDialogOpen} onClose={ctx.previous} >
      <RoundIcon Icon={StarRoundedIcon} iconSize={60} />
      <Typography sx={{ p: 2, fontWeight: 700, textAlign: 'center', }}>{ctx.t.recurringTransferSuccess({
        amount: conf.recurringTransferAmount + ' ' + ctx.user.currency.short,
        frequency: ctx.t.frequencyPeriod[conf.recurringTransferFrequency].toLocaleLowerCase(),
      })}</Typography>
    </FullScreenDialogInfo>
  </Page>
}

export default VaultRecurringTransferPage