import * as React from 'react';
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { useTheme } from '@mui/material/styles'
import AccountPreviewItem from './AccountPreviewItem'
import { ReactComponent as HorseIcon } from '../01_shared/assets/horse-icon.svg'
import { Link } from "react-router-dom";
import ActionCard from '../01_shared/ActionCard'
import { appCtx } from '../01_shared/appContext'
import TransactionList from 'src/01_shared/TransactionList'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded'
import NewVaultDrawer from 'src/vaults/NewVaultDrawer'
import generalConfig from '../01_shared/general-config'
import DevModeDrawer from 'src/devmode/DevModeDrawer'
import FinancialHeaderResume from 'src/01_shared/FinancialHeaderResume'

import { isset } from 'topkat-utils'

// let devModeActive = false

const Dashboard = ({ appVersion }) => {
  const theme = useTheme()
  const ctx = React.useContext(appCtx)

  const [openNewVaultDrawer, setOpenNewVaultDrawer] = React.useState(false)
  const [devModeDrawerOpen, setDevModeOpen] = React.useState(false)

  return <div data-trk='ignore'>
    <div style={{ position: 'absolute', zIndex: 9, right: 8, top: 8, fontSize: 12, color: 'rgba(100,100,100,0.15', fontWeight: 700, }} onClick={() => {
      // activateDevMode()
      // if (devModeActive) 
      setDevModeOpen(true)
    }}>v{appVersion}</div>
    {/* <DashboardBg style={{ position: 'absolute', zIndex: 0, top: 0, left: 0, maxWidth: '90vw', maxHeight: '90vw' }} /> */}
    <div style={{ zIndex: 1, position: 'relative' }}>
      <Box px={2} pt={5} pb={0}>
        <ProfilePic data-trk='a-profilePicLink-to:profile' ctx={ctx} theme={theme} mb={5} />
        {/* MONEY DISPLAY */}
        <FinancialHeaderResume />
        {/* HORIZONTAL ACCOUNT SLIDER */}
        <DashboardTitle title={ctx.t.myWallets} dtaAction='newVault' linkOnClick={() => setOpenNewVaultDrawer(true)} linkText={ctx.t.createNew} />
      </Box>

      <Box p={2} pt={0} sx={{ overflowX: 'auto', overflowY: 'visible', whiteSpace: 'nowrap', msOverflowStyle: 'none', scrollbarWidth: 'none', '&::-webkit-scrollbar': { display: 'none', } }}>
        {ctx.user.accounts.map((accountData, i) => <AccountPreviewItem dataTrkName={`accountPreviewItem${i}`} key={i} linkTarget={`vault/${accountData.id}`} title={accountData.name} accountDatas={accountData} />)}
        <AccountPreviewItem dataTrkName='accountPreviewItemNewCrypto' linkTarget='vault/new/crypto' disabled title={ctx.t.cryptoVaultTitle} isActionBtn={true} />
        <AccountPreviewItem dataTrkName='accountPreviewItemNewSaving' linkTarget='vault/new/saving' title={ctx.t.savingVaultTitle} isActionBtn={true} />
      </Box>


      <Box p={2}>
        {/* VAULT CALL TO ACTION */}
        <VaultCta />
        {/* TRANSACTION LIST */}
        <DashboardTitle title={ctx.t.transactionHistory} linkHref='/transaction/all' linkText={ctx.t.viewAll} />
        <TransactionList transactions={ctx.user.transactions} />
        {/* POPULAR CRYPTO PANEL */}
        <DashboardTitle title={ctx.t.popularCryptocurrencies} />
        <PopularCryptoPanel />
      </Box>

      <NewVaultDrawer open={openNewVaultDrawer} setOpen={setOpenNewVaultDrawer} />
      <DevModeDrawer open={devModeDrawerOpen} setOpen={setDevModeOpen} version={appVersion} />
    </div>
  </div>
}

export default Dashboard


type DashboardTitlePropsType = { title: string, linkHref?: string, linkText?: string, linkOnClick?: () => void, dtaAction?: string }
function DashboardTitle({ title, linkHref, linkText, linkOnClick, dtaAction, ...props }: DashboardTitlePropsType) {
  let linkComponent: any = null
  if (linkText && linkHref) linkComponent = <Link data-trk={`l-dashboardTitleAction-to:${linkHref}`} to={linkHref} style={{ textAlign: 'right' }}>{linkText}</Link>
  else if (linkText && linkOnClick) linkComponent = <Typography data-trk={`a-dashboardTitleAction-action:${dtaAction}`} variant='primary' onClick={linkOnClick} style={{ textAlign: 'right' }}>{linkText}</Typography>
  return <Typography mt={4} mb={2} variant='h2' component={'div'} sx={{ display: 'flex', justifyContent: 'space-between' }} {...props}>
    {title}
    {linkComponent}
  </Typography>
}

function ProfilePic({ ctx, theme, ...props }) {
  const size = 56
  return <Box style={{ width: size, height: size, position: 'relative', alignItems: 'center', justifyContent: 'center', display: 'flex' }} {...props} >
    <div style={{ position: 'absolute', transform: 'rotate(45deg)', border: `1px solid ${theme.palette.primary.main}`, width: '100%', height: '100%', borderRadius: 10 }}></div>
    {isset(ctx.user.picture) ? <img alt='' src={ctx.user.picture} /> : <Typography color='textPrimary'>{((ctx.user.firstName || 'A')[0] + (ctx.user.lastName || 'A')[0]).toUpperCase()}</Typography>}
  </Box>
}

function VaultCta() {
  const theme = useTheme()
  const ctx = React.useContext(appCtx)
  return <Paper>
    <ActionCard
      data-trk-name='vaultCallToActionCard'
      title={ctx.t.vaultInfoCta.title}
      leftIcon={<HorseIcon fill={theme.palette.primary.main} />}
      subtitle={<Typography variant='primary'>{ctx.t.vaultInfoCta.link}</Typography>}
      cardLink="/vault/new/saving"
      description={ctx.t.vaultInfoCta.description}
    />
  </Paper>
}


function PopularCryptoPanel() {
  const theme = useTheme()

  return <Paper data-trk='c-popularCryptoPanel'>
    <Box p={2}>
      <Grid container spacing={2}>
        {generalConfig.popularCrypto.map(crypto => <Grid item xs={3} key={crypto.fullName} >
          <Box className='center'>
            <Avatar variant='rounded' sx={{ bgcolor: theme.palette.bgDarkest.main, mb: 2 }}>
              <StarBorderRoundedIcon />
            </Avatar>
            <Typography fontWeight={700}>{crypto.fullName}</Typography>
            <Typography fontSize={'small'} color={crypto.lastChange < 0 ? theme.palette.error.main : theme.palette.primary.main}>{crypto.lastChange.toFixed(2) + '%'}</Typography>
          </Box>
        </Grid>)}
      </Grid>
    </Box>
  </Paper>
}

// let devModeClickNb = 0
// function activateDevMode() {
//   devModeClickNb++
//   if (devModeClickNb === 5) alert('DEV MODE ACTIVE')
//   else if (devModeClickNb > 5) devModeActive = true
// }