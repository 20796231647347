// import * as React from 'react'
import Button from '@mui/material/Button'

// data-trk ignoreFile
const FullWidthButton = ({ children, ...props }) => {
  return <Button sx={{ mt: 2, py: 2, width: '100%', fontSize: '1rem' }} variant="contained" size="large" {...props}>
    {children}
  </Button>
}

export default FullWidthButton