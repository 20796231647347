import * as React from 'react'
import { Routes, Route, unstable_HistoryRouter as HistoryRouter } from "react-router-dom"
import Dashboard from './dashboard/Dashboard'
import Page1 from './Page1'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import LoginPage from './LoginPage';
import FixedBottomNavMenu from './01_shared/fixed-bottom-nav-menu'
import translations from './01_shared/translations'
import Loader from './01_shared/Loader'
import { appCtx, defaultCtx } from './01_shared/appContext'
import VaultMainPage from './vaults/VaultMainPage'
import { useNavigate } from "react-router-dom"
import NewVaultEditTitleAndColorPage from './vaults/NewVaultEditTitleAndColorPage'
import DailyInterestConfigPage from './vaults/DailyInterestConfigPage'
import VaultFirstDepositPage from './vaults/VaultFirstDepositPage'
import VaultSavingGoalPage from './vaults/VaultSavingGoalPage'
import VaultRecurringTransferPage from './vaults/VaultRecurringTransferPage'
import NewSavingVaultPage from './vaults/NewVaultBuilderPage'
import SimulateEarningPage from './vaults/SimulateEarningPage'
import DailyInterestLearnMorePage from './vaults/DailyInterestLearnMorePage'
import PremiumVaultTransferPage from './vaults/PremiumVaultTransferPage'
import { history } from './00_core/navigation-helper'
import WealthHubPage from './01_shared/WealthHubPage'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import ScrollToTop from './00_core/ScrollToTop'
import VERSION from './VERSION'

import { isset, mergeDeep } from 'topkat-utils'

if (window.location.pathname !== '/') window.location.pathname = '/'

// data-trk ignoreFile
let deferredPrompt

const isAppInstalled = window.matchMedia('(display-mode: standalone)').matches
const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
const isLocalhost = window.location.host.includes('localhost')
window.addEventListener('beforeinstallprompt', e => deferredPrompt = e)

const updateCtx = (user, setIsLogged, setUser, changeLanguage) => {
  if (!isset(user)) {
    localStorage.removeItem('user')
    setIsLogged(false)
  } else {
    localStorage.setItem('userId', user._id)
    localStorage.setItem('user', JSON.stringify(user))
    setIsLogged(true)
    setUser(user)
    changeLanguage(user.lang || 'en')
  }
}

function App() {

  const [isLoading, setIsLoading] = React.useState(true)
  const [actualPage, setActualPage] = React.useState('dashboard')
  const [language, changeLanguage] = React.useState('en')
  const [user, setUser] = React.useState({})
  const [isLogged, setIsLogged] = React.useState(false)
  const [showInstallDialogState, setShowInstallDialog] = React.useState(isMobile)

  const showInstallDialog = showInstallDialogState && !isLocalhost && !isAppInstalled

  const updateUser = user => {
    updateCtx(user, setIsLogged, setUser, changeLanguage)
  }

  const newCtx = mergeDeep(defaultCtx, {
    user,
    t: translations[language],
    changeLanguage,
    refresh() {
      localStorage.setItem('user', JSON.stringify(this.user))
      setAppContext({ ...this })
    },
    logout() {
      updateUser(null)
      window.location.pathname = ''
    },
  })

  const [appContext, setAppContext] = React.useState(newCtx)

  React.useEffect(() => {
    const userFromLs = localStorage.getItem("user")

    if (userFromLs) {
      const user = JSON.parse(userFromLs)
      updateCtx(user, setIsLogged, setUser, changeLanguage)
    }
    // hide dialog in case beforeinstallprompt was not triggered
    setTimeout(() => {
      if (!isset(deferredPrompt)) setShowInstallDialog(false)
    }, 800)
    setIsLoading(false)
  }, [setIsLogged])

  return <>
    <HistoryRouter history={history}>
      {isLoading ? <Loader /> : !isLogged ? <LoginPage updateUser={updateUser} /> :
        <>
          <ScrollToTop />
          <appCtx.Provider value={appContext}>
            <Routes>
              {/* NEW VAULT */}
              <Route index element={<Dashboard appVersion={VERSION} />} />
              {//appContext.user.activeAccount ?
                <>
                  <Route path="/vault/saving/config" element={<NewVaultEditTitleAndColorPage />} />
                  <Route path="/vault/saving/firstDeposit" element={<VaultFirstDepositPage />} />
                  <Route path="/vault/saving/savingGoal" element={<VaultSavingGoalPage />} />
                  <Route path="/vault/saving/recurringTransfer" element={<VaultRecurringTransferPage />} />
                  <Route path="/vault/saving/dailyInterest" element={<DailyInterestConfigPage />} />
                  <Route path="/vault/saving/dailyInterest/simulate" element={<SimulateEarningPage />} />
                  <Route path="/vault/saving/dailyInterest/learnMore" element={<DailyInterestLearnMorePage />} />
                  <Route path="/vault/premium/activate" element={<PremiumVaultTransferPage />} />
                </>
                //    : null
              }
              <Route path="/vault/:vaultId" element={<VaultMainPage />} />
              <Route path="/vault/new/saving" element={<NewSavingVaultPage />} />
              <Route path='/wealth-hub' element={<WealthHubPage />} />
              <Route path="/page1" element={<Page1 />} />
              <Route path="/page2" element={<Page1 />} />
              <Route path="/page3" element={<Page1 />} />
              <Route path='*' element={<Page404 />} />
            </Routes>
          </appCtx.Provider>
          <FixedBottomNavMenu actualPage={actualPage} setActualPage={setActualPage} />
          <div style={{ height: 130 }}></div> {/* Ensure there is always room for scrolling above the fixed menu */}
        </>}
      <ToastContainer />
      {/* APP INSTALL DIALOG */}
      <Dialog
        open={showInstallDialog}
        onClose={() => setShowInstallDialog(false)}
      >
        <DialogContent>
          <DialogContentText >
            {appContext.t.installAppDialogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowInstallDialog(false)}>{appContext.t.cancel}</Button>
          <Button onClick={async () => {
            setShowInstallDialog(false)
            if (isset(deferredPrompt)) {
              deferredPrompt.prompt();
              const { outcome } = await deferredPrompt.userChoice;
              if (outcome === 'accepted') deferredPrompt = null
            }
          }} autoFocus>
            {appContext.t.installAppBtn}
          </Button>
        </DialogActions>
      </Dialog>
    </HistoryRouter>
  </>
}

export default App;

function Page404() {
  const navigate = useNavigate()
  console.log(`404 REDIRECT`);
  React.useEffect(() => {
    navigate('/') // SIMPLE REDIRECT
  })
  return <div>404</div>
}
