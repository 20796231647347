import * as React from 'react'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import { Link } from "react-router-dom";
import { useTheme } from '@mui/material/styles'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import * as t from '../01_shared/types'

type AccountPreviewItemProps = ({
  isActionBtn?: false
  accountDatas: { currency: { symbol: string, short: string }, totalAmount: number, type: t.AccountTypeEnum }
} | {
  isActionBtn: true
}) & {
  title: string
  disabled?: boolean
  linkTarget: string
  dataTrkName: string
}
const AccountPreviewItem = ({ title, linkTarget, disabled = false, dataTrkName, ...props }: AccountPreviewItemProps) => {
  const theme = useTheme()

  const itemSize = 172
  const titleColor = disabled ? theme.palette.text.disabled : props.isActionBtn ? theme.palette.text.primary : null
  const paperStyle: any = { width: itemSize, height: itemSize, display: 'inline-block', mr: 2, cursor: disabled ? 'not-allowed' : 'pointer' }
  if (disabled) paperStyle.color = theme.palette.text.disabled

  let centralComponent
  if (props.isActionBtn) {
    // ACTION BTN
    centralComponent = <Box sx={{ flex: '1 1 100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><AddRoundedIcon sx={{ color: disabled ? theme.palette.text.disabled : theme.palette.primary.main, fontSize: 40, fontWeight: 'bold' }} /></Box>
  } else {
    // VAULT DATA
    const totalAmountEuros = Math.floor(props.accountDatas.totalAmount)
    const totalAmountCents = (props.accountDatas.totalAmount - totalAmountEuros).toFixed(2).replace('0.', '')
    centralComponent = <Typography sx={{ flex: '0 0' }} fontWeight={700}>
      <span style={{ fontSize: 24 }}>{totalAmountEuros}</span>,<span style={{ fontSize: 14 }}>{totalAmountCents} {props.accountDatas.currency.symbol}</span><br />
    </Typography>
  }

  return <div data-trk={`c-${dataTrkName}`} style={{ display: 'inline-block' }}>
    <Link data-trk={`l-${dataTrkName}Link-to:${linkTarget}`} to={linkTarget}>
      <Paper sx={paperStyle}>
        <Box p={2} sx={{ display: 'flex', flexDirection: 'column', height: '100%', boxSizing: 'border-box' }}>
          <Typography fontSize={14} fontWeight={700} {...(titleColor ? { color: titleColor } : {})}>{title}</Typography>
          {centralComponent}
        </Box>
      </Paper>
    </Link>
  </div>
}

export default AccountPreviewItem