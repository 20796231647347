import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import { appCtx } from '../01_shared/appContext'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import * as t from '../01_shared/types'
import { convert } from '../01_shared/services/convert'
import BottomDrawer from 'src/01_shared/BottomDrawer'
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded'
import SouthEastIcon from '@mui/icons-material/SouthEastRounded'
import NorthEastIcon from '@mui/icons-material/NorthEastRounded'

import { addDays } from 'topkat-utils'

type FinancialHearderResumeProps = { account?: t.UserAccountType }
function FinancialHeaderResume({ account, ...props }: FinancialHearderResumeProps) {

  const theme = useTheme()
  const ctx = React.useContext(appCtx)
  const earningPeriods = ['all', 'week', 'month', 'last24h'] as t.EarningPeriodType[]
  const [earningPeriod, setEarningPeriod] = React.useState('all' as t.EarningPeriodType)
  const [displayMoneyDiscaimer, setDisplayMoneyDiscaimer] = React.useState(false)
  const allTimes = typeof account === 'undefined'

  const mainCurrency = allTimes ? ctx.user.currency : account.currency
  const userCurrency = ctx.user.currency
  const totalAmount = allTimes ? ctx.user.totalAmount : account.totalAmount
  const totalAmountUserCurrency = allTimes ? totalAmount : convert(mainCurrency.id, userCurrency.id, account.totalAmount)
  const totalAmountInteger = Math.floor(totalAmount)
  const totalAmountCents = (totalAmount - totalAmountInteger).toFixed(2).replace('0.', '')

  const minPeriodDate = addDays(new Date(), earningPeriod === 'last24h' ? -1.1 : earningPeriod === 'week' ? 7 : earningPeriod === 'month' ? -31 : -999)
  const transactionFilter = (tr: t.AccountTransaction) =>
    tr.type === 'earn' &&
    (earningPeriod === 'all' || tr.depositDate > minPeriodDate) &&
    (allTimes || tr.accountTarget === account.id)
  const earnTransactions = ctx.user.transactions.filter(transactionFilter)

  const lastPeriodRevenueInUserCur = convert(mainCurrency.id, userCurrency.id, earnTransactions.reduce((sum, tr) => tr.amountTarget + sum, 0))
  const lastPeriodRevenuePercent = ((100 / (totalAmountUserCurrency || 1)) * lastPeriodRevenueInUserCur).toFixed(2)

  const questionMarkSize = 15
  const questionMarkStyle: any = { fontSize: 10, textAlign: 'center', backgroundColor: theme.palette.bgDarkest.main, borderRadius: '50%', height: questionMarkSize, width: questionMarkSize, lineHeight: (questionMarkSize + 3) + 'px', display: 'inline-block', marginLeft: 10, verticalAlign: 'super' }
  const hideAmountInUserCurrency = allTimes || account.currency.id === ctx.user.currency.id

  const togglePeriod = () => {
    const actualPeriodIndex = earningPeriods.indexOf(earningPeriod)
    setEarningPeriod(earningPeriods[(actualPeriodIndex + 1) % earningPeriods.length])
  }

  return <Box data-trk='c-financialHeaderResume' {...props}>
    <Typography fontWeight={700} component='div'>
      {/* BIG AMOUNT LINE */}
      <div style={{ lineHeight: 1.2 }}>
        <span style={{ fontSize: 40 }}>{totalAmountInteger}</span>,<span style={{ fontSize: 20 }}>{totalAmountCents} {mainCurrency.short}</span>
        {mainCurrency.id in ctx.t.moneyDisclaimer ? <span data-trk={`a-moneyDisclaimer-${mainCurrency.id}`} style={questionMarkStyle} onClick={() => setDisplayMoneyDiscaimer(true)}>?</span> : null}
      </div>

      <div style={{ userSelect: 'none' }}>
        {/* AMOUNT IN USER CURRENCY */}
        {hideAmountInUserCurrency ? null : <div style={{ width: 100 }}>{totalAmountUserCurrency.toFixed(2) + ctx.user.currency.symbol}</div>}
        {/* GAIN BY PERIOD IN USER CURRENCY */}
        <div style={{ display: 'inline-block', width: 90 }}>+{lastPeriodRevenueInUserCur.toFixed(2) + ctx.user.currency.symbol}</div>
        {/* GAIN BY PERIOD IN PERCENT */}
        <div style={{ display: 'inline-block', width: 90, color: lastPeriodRevenueInUserCur === 0 ? undefined : lastPeriodRevenueInUserCur > 0 ? 'green' : 'red' }}>
          {lastPeriodRevenueInUserCur === 0 ? '' : lastPeriodRevenueInUserCur > 0 ? <NorthEastIcon style={{ fontSize: 14 }} /> : <SouthEastIcon style={{ fontSize: 14 }} />} {lastPeriodRevenuePercent}%
        </div>
        {/* EARNING PERIOD SELECT */}
        <Typography data-trk={`a-toggleFinanceResumePeriod-${earningPeriod}`} variant='primary' onClick={() => togglePeriod()}>{ctx.t.earnPeriodLabels[earningPeriod]}<UnfoldMoreRoundedIcon style={{ position: 'relative', top: 5 }} /></Typography>
      </div>
    </Typography>
    {!allTimes ? <BottomDrawer open={displayMoneyDiscaimer} setOpen={setDisplayMoneyDiscaimer}>
      <Box p={2} style={{ textAlign: 'center', }}>
        {account.currency.id in ctx.t.moneyDisclaimer ? ctx.t.moneyDisclaimer[account.currency.id] : null}
      </Box>
    </BottomDrawer> : null}
  </Box>
}

export default FinancialHeaderResume