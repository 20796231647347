import tracker from './Tracker'
import trackerConfig from './tracker-config'
import { getNextParentElmTrkProps } from './dom-helper'
import { extractDataTrkPropValue } from './dom-helper'
import { componentConfigs } from './components-configs'

import { isset, round } from 'topkat-utils'

//----------------------------------------
// CLICK
//----------------------------------------
const clickHandler = e => {
  const { componentConfig } = getNextParentElmTrkProps(e.target as Element)
  if (componentConfig) {
    // TODO DELETEME backend behavior


    const rageClickTracker = componentConfig.rageClickTracker
    rageClickTracker.nbClick++
    clearTimeout(rageClickTracker.timer)
    rageClickTracker.timer = setTimeout(() => {
      // send click or rage event
      const meta = { x: e.clientX, y: e.clientY, xPercent: round(e.clientY / document.body.clientHeight, 2), yPercent: round(e.clientX / window.innerWidth, 2) }

      if (rageClickTracker.nbClick > 2) {
        tracker.sendEvent('rage-click', componentConfig, { ...meta, nbClicks: rageClickTracker.nbClick })
        componentConfig.rageClicks++
      } else {
        const isMissedClick = !['a', 'l'].includes(componentConfig.type)
        componentConfig[isMissedClick ? 'missedClicks' : 'clicks']++
        tracker.sendEvent(isMissedClick ? 'missed-click' : 'click', componentConfig, meta)
      }

      componentConfig.rageClickTracker = { nbClick: 0 }

    }, trackerConfig.nbMsForRageClickCounterWait);
  }
}

//----------------------------------------
// SWIPE
//----------------------------------------
let touchstartX = 0
let touchstartY = 0
const touchStartHandler = e => {
  touchstartX = e.changedTouches[0].screenX
  touchstartY = e.changedTouches[0].screenY
}
const touchEndHandler = e => {
  const touchendX = e.changedTouches[0].screenX
  const distance = Math.abs(touchendX - touchstartX)
  if (distance >= trackerConfig.swipeDetectionMinTravelDistancePx) {
    const { componentConfig } = getNextParentElmTrkProps(e.target as Element)
    if (componentConfig) {
      const eventType = touchendX < touchstartX ? 'swipe-left' : 'swipe-right'
      tracker.sendEvent(eventType, componentConfig, { x: touchstartX, y: touchstartY })
    }
  }
}

//----------------------------------------
// INPUTS
//----------------------------------------
const inputFocusHandler = e => {
  const { componentConfig } = getNextParentElmTrkProps(e.target as Element)
  if (componentConfig) tracker.sendEvent('input-edit-start', componentConfig)
}
const inputBlurHandler = e => {
  const { componentConfig } = getNextParentElmTrkProps(e.target as Element)
  if (componentConfig) {
    // TRACK EDITS IF ANY
    if (componentConfig.inputEditHistory.length) tracker.sendEvent('input-edit', componentConfig, { edit: componentConfig.inputEditHistory })
    // EDIT END EVENT
    tracker.sendEvent('input-edit-end', componentConfig)
    componentConfig.inputEditHistory = []
  }
}

//----------------------------------------
// INPUT EDITS
//----------------------------------------
const inputEditHandler = e => {
  if (!e.repeat && !['Control', 'Alt', 'Shift'].includes(e.key)) {
    const { componentConfig } = getNextParentElmTrkProps(e.target as Element)
    if (componentConfig) {
      const obfuscatedInput = true // htmlAttrValue !== 'clear'

      const keyArr: [number, ...string[]] = [Date.now()]
      if (e.metaKey) keyArr.push('meta')
      if (e.ctrlKey) keyArr.push('ctrl')
      if (e.shiftKey) keyArr.push('shift')
      if (e.altKey) keyArr.push('alt')
      keyArr.push(obfuscatedInput && keyArr.length === 1 && /^[a-zA-Z0-9]$/.test(e.key) ? 'alphaNum' : e.key)
      componentConfig.inputEditHistory.push(keyArr)
    }
  }
}

//----------------------------------------
// SESSION END
//----------------------------------------
window.onbeforeunload = () => tracker.endSession()

//----------------------------------------
// APP FOCUS HANDLER
//----------------------------------------
const appFocusHandler = () => {
  if (document.visibilityState === 'visible') tracker.appGainFocus()
  else tracker.appLoseFocus()
}

//----------------------------------------
// VISIBILITY TRACKER
//----------------------------------------
const visibilityObserver = new IntersectionObserver(entries => {
  try {
    for (const entry of entries) {
      const { isIntersecting: isVisible, target: elm } = entry
      const { componentConfig } = extractDataTrkPropValue(elm)
      if (componentConfig) {
        if (componentConfig.isVisible === isVisible) continue
        let sendEvent = true

        if (!isset(componentConfig.isVisible)) {
          if (isVisible) componentConfig.isVisible = isVisible // first time visible
          else sendEvent = false
        } else componentConfig.isVisible = isVisible

        if (sendEvent) tracker.sendEvent(`visible-${isVisible ? 'start' : 'end'}`, componentConfig)
      }
    }
  } catch (err) {
    console.error(err)
  }
}, { threshold: trackerConfig.percentageItemInViewportToConsiderVisible })

function visibilityListener() {
  visibilityObserver.disconnect()
  Object.values(componentConfigs).forEach(trkConfig => visibilityObserver.observe(trkConfig.elm))
}



//----------------------------------------
// LISTENERS
//----------------------------------------
document.removeEventListener('click', clickHandler) // avoid bug on dev hot reloading
document.addEventListener('click', clickHandler)

document.removeEventListener('touchstart', touchStartHandler)
document.addEventListener('touchstart', touchStartHandler)
document.removeEventListener('touchend', touchEndHandler)
document.addEventListener('touchend', touchEndHandler)

document.removeEventListener('visibilitychange', appFocusHandler)
document.addEventListener('visibilitychange', appFocusHandler)

document.removeEventListener('keyup', inputEditHandler)
document.addEventListener('keyup', inputEditHandler)

document.removeEventListener('focusin', inputFocusHandler)
document.addEventListener('focusin', inputFocusHandler)

document.removeEventListener('blur', inputBlurHandler)
document.addEventListener('blur', inputBlurHandler)

export {
  visibilityListener
}