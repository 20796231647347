
import { ThemeOptions } from '@mui/material/styles'
import styleMixins from './style-mixins'
import trackerStyle from './tracker-style'
import themeVarz from './theme-varz'


const globalStyles: any = {
  body: {
    fontFamily: themeVarz.defaultFontFamily,
  },
  '*': {
    boxSizing: 'border-box'
  },
  'html, body': {
    backgroundColor: themeVarz.veryLightGrey,
    minHeight: '100vh',
    margin: 0,
    padding: 0,
    fontSize: 14,
    scrollBehavior: 'smooth',
  },
  '.center': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  '.overlay': styleMixins.overlay,
  a: {
    all: 'unset',
  },
  'a, .link': {
    cursor: 'pointer',
    color: themeVarz.primaryBlue,
    fontWeight: 700,
    // display: 'inline-block',
  },
  '.text-primary': {
    color: themeVarz.primaryBlue,
  },
  '.input-bold input': {
    fontWeight: 700,
  },
  '.row': {
    display: 'flex',
    justifyContent: 'space-between',
    elignItems: 'center',
  },
  '.bold': { fontWeight: 700 },
  'expandable': {
    transition: 'height .2s',
  },
  '[data-trk]': {
    position: 'relative',
  },
  ...trackerStyle,
}

/** THEME HELPER:
 * https://bareynol.github.io/mui-theme-creator/ 
 */
const themes: { [k: string]: ThemeOptions } = {
  //----------------------------------------
  // AKT LIGHT
  //----------------------------------------
  aktLight: {
    palette: {

      mode: 'light',
      primary: {
        main: themeVarz.primaryBlue, // blue primary
        light: themeVarz.primaryLight,
      },
      secondary: {
        main: themeVarz.darkNavyBlue, // dark navy blue
      },
      error: {
        main: `#F34949`,
      },
      text: {
        primary: themeVarz.primaryBlue, // blue primary
        secondary: themeVarz.darkNavyBlue, // dark navy blue
        disabled: themeVarz.lightGrey,
      },
      background: {
        default: '#F6F8FA', // very light grey
        paper: `#FFFFFF`,
      },
      bgDarker: {
        main: '#E1E8F1' // light grey
      },
      bgDarkest: {
        main: themeVarz.lightGrey
      },
      disabled: {
        main: themeVarz.lightGrey,
        light: `#F2F6F9`,
      },
      info: {
        main: themeVarz.primaryBlue,
      },
      action: {
        disabled: themeVarz.lightGrey,
      },
    },
    shape: {
      borderRadius: 10,
    },
    shadows: [
      "none",
      "0px 6px 10px 0px rgba(0,0,0,0.07),0px 1px 18px 0px rgba(0,0,0,0.06)",
      "0px 4px 5px -2px rgba(0,0,0,0.06),0px 7px 10px 1px rgba(0,0,0,0.07),0px 2px 16px 1px rgba(0,0,0,0.06)",
      "0px 5px 5px -3px rgba(0,0,0,0.06),0px 8px 10px 1px rgba(0,0,0,0.07),0px 3px 14px 2px rgba(0,0,0,0.06)",
      "0px 5px 6px -3px rgba(0,0,0,0.06),0px 9px 12px 1px rgba(0,0,0,0.07),0px 3px 16px 2px rgba(0,0,0,0.06)",
      "0px 6px 6px -3px rgba(0,0,0,0.06),0px 10px 14px 1px rgba(0,0,0,0.07),0px 4px 18px 3px rgba(0,0,0,0.06)",
      "0px 6px 7px -4px rgba(0,0,0,0.06),0px 11px 15px 1px rgba(0,0,0,0.07),0px 4px 20px 3px rgba(0,0,0,0.06)",
      "0px 7px 8px -4px rgba(0,0,0,0.06),0px 12px 17px 2px rgba(0,0,0,0.07),0px 5px 22px 4px rgba(0,0,0,0.06)",
      "0px 7px 8px -4px rgba(0,0,0,0.06),0px 13px 19px 2px rgba(0,0,0,0.07),0px 5px 24px 4px rgba(0,0,0,0.06)",
      "0px 7px 9px -4px rgba(0,0,0,0.06),0px 14px 21px 2px rgba(0,0,0,0.07),0px 5px 26px 4px rgba(0,0,0,0.06)",
      "0px 8px 9px -5px rgba(0,0,0,0.06),0px 15px 22px 2px rgba(0,0,0,0.07),0px 6px 28px 5px rgba(0,0,0,0.06)",
      "0px 8px 10px -5px rgba(0,0,0,0.06),0px 16px 24px 2px rgba(0,0,0,0.07),0px 6px 30px 5px rgba(0,0,0,0.06)",
      "0px 8px 11px -5px rgba(0,0,0,0.06),0px 17px 26px 2px rgba(0,0,0,0.07),0px 6px 32px 5px rgba(0,0,0,0.06)",
      "0px 9px 11px -5px rgba(0,0,0,0.06),0px 18px 28px 2px rgba(0,0,0,0.07),0px 7px 34px 6px rgba(0,0,0,0.06)",
      "0px 9px 12px -6px rgba(0,0,0,0.06),0px 19px 29px 2px rgba(0,0,0,0.07),0px 7px 36px 6px rgba(0,0,0,0.06)",
      "0px 10px 13px -6px rgba(0,0,0,0.06),0px 20px 31px 3px rgba(0,0,0,0.07),0px 8px 38px 7px rgba(0,0,0,0.06)",
      "0px 10px 13px -6px rgba(0,0,0,0.06),0px 21px 33px 3px rgba(0,0,0,0.07),0px 8px 40px 7px rgba(0,0,0,0.06)",
      "0px 10px 14px -6px rgba(0,0,0,0.06),0px 22px 35px 3px rgba(0,0,0,0.07),0px 8px 42px 7px rgba(0,0,0,0.06)",
      "0px 11px 14px -7px rgba(0,0,0,0.06),0px 23px 36px 3px rgba(0,0,0,0.07),0px 9px 44px 8px rgba(0,0,0,0.06)",
      "0px 11px 15px -7px rgba(0,0,0,0.06),0px 24px 38px 3px rgba(0,0,0,0.07),0px 9px 46px 8px rgba(0,0,0,0.06)",
      "0px 4px 2px -2px rgba(0,0,0,0.06),0px 2px 2px 0px rgba(0,0,0,0.07),0px 2px 6px 0px rgba(0,0,0,0.06)",
      "0px 3px 1px -2px rgba(0,0,0,0.06),0px 2px 2px 0px rgba(0,0,0,0.07),0px 1px 5px 0px rgba(0,0,0,0.06)",
      "0px 3px 3px -2px rgba(0,0,0,0.06),0px 3px 4px 0px rgba(0,0,0,0.07),0px 1px 8px 0px rgba(0,0,0,0.06)",
      "0px 2px 4px -1px rgba(0,0,0,0.06),0px 4px 5px 0px rgba(0,0,0,0.07),0px 1px 10px 0px rgba(0,0,0,0.06)",
      "0px 3px 5px -1px rgba(0,0,0,0.06),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.06)",
    ],
    typography: {
      fontSize: 14,
      allVariants: {
        color: themeVarz.darkNavyBlue,
        fontSize: 14,
      },
      primary: {
        color: themeVarz.primaryBlue,
        fontWeight: 700,
      },
      body1: {
        fontSize: 14,
      },
      disabled: {
        color: themeVarz.lightGrey,
      },
      h1: {
        fontSize: 24,
        fontWeight: 700,
      },
      h2: {
        fontSize: 16,
        fontWeight: 700,
      },
      h3: {
        fontSize: 16,
        fontWeight: 700,
      },
      h4: {
        fontWeight: 700,
      },
      subtitle2: {
        color: '#ADC0CE'
      }
    },
    components: {
      MuiLink: {
        defaultProps: {
          underline: 'none',
        },
        ...rootStyle({
          fontWeight: 700,
          cursor: 'pointer',
          textTransform: 'none',
        })
      },
      MuiFormLabel: rootStyle({ fontWeight: 700, color: themeVarz.darkNavyBlue }),
      MuiButton: style({
        root: {
          fontWeight: 700,
          fontSize: 14,
          textTransform: 'unset',
        },
        contained: {
          color: '#fff'
        }
      }),
      MuiPaper: rootStyle({ position: 'relative', color: themeVarz.darkNavyBlue }),
      MuiAvatar: rootStyle({ width: 52, height: 52, }),
      MuiLinearProgress: rootStyle({ borderRadius: 3, height: 6 }),
      MuiChip: {
        styleOverrides: {
          root: {
            color: themeVarz.darkNavyBlue,
            fontWeight: 700,
          },
          colorPrimary: {
            color: themeVarz.primaryBlue,
            backgroundColor: themeVarz.primaryLight
          }
        }
      },
      MuiInput: rootStyle({
        color: themeVarz.darkNavyBlue,
        "&.Mui-disabled": {
          "& .MuiInputAdornment-root p": {
            color: themeVarz.lightGrey,
          },
        }
      }),
    },
  },
}

function style(style) {
  return { styleOverrides: { ...style } }
}

function rootStyle(style) {
  return { styleOverrides: { root: { ...style } } }
}



export {
  themes,
  globalStyles,
}




//----------------------------------------
// TYPES
//----------------------------------------
declare module '@mui/material/styles' {
  interface TypographyVariants {
    primary: React.CSSProperties
    disabled: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    primary?: React.CSSProperties;
    disabled?: React.CSSProperties;
  }

  interface Palette {
    bgDarker: Palette['primary'];
    bgDarkest: Palette['primary'];
    disabled: Palette['primary'];
  }
  interface PaletteOptions {
    bgDarker: PaletteOptions['primary'];
    bgDarkest: PaletteOptions['primary'];
    disabled: PaletteOptions['primary'];

  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    primary: true
    disabled: true
  }
}