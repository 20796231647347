import * as React from 'react'
import Typography from '@mui/material/Typography'
import { Link } from "react-router-dom"
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { ReactComponent as HorseIcon } from '../01_shared/assets/horse-icon.svg'
import { ReactComponent as DiamondIcon } from '../01_shared/assets/diamond-icon.svg'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import SyncAltRoundedIcon from '@mui/icons-material/SyncAltRounded'
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded'
import EmojiFlagsRoundedIcon from '@mui/icons-material/EmojiFlagsRounded'
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import { ReactComponent as ParIcon } from '../01_shared/assets/par-icon.svg'
import RoundIcon from 'src/01_shared/RoundIcon'
import { useTheme } from '@mui/material/styles'
import { appCtx } from '../01_shared/appContext'
import * as t from '../01_shared/types'
import { dateSlash } from 'topkat-utils'


type VaultBuilderItemsProps = { account: t.UserAccountType, firstTime: boolean }
function VaultBuilderItems({ account, firstTime }: VaultBuilderItemsProps) {
  const ctx = React.useContext(appCtx)

  return <div data-trk={`c-vaultBuilder`} >
    {/* DAILY INTEREST */}
    <MiniActionCard
      CardIcon={account.isPremium ? HorseIcon : account.isSmart ? ParIcon : StarBorderRoundedIcon}
      cardLink='/vault/saving/dailyInterest'
      isActive={account.isPremium || account.isSmart}
    >
      {account.isPremium ? <>
        <Typography variant='primary'>{ctx.t.premiumVaultActivated}</Typography>
        <Typography fontWeight={700}>{ctx.t.dailyInterestUpToBoosted}</Typography>
      </> : account.isSmart ? <>
        <Typography variant='primary'>{ctx.t.smartVaultActivated}</Typography>
        <Typography fontWeight={700}>{ctx.t.dailyInterestUpTo}</Typography>
      </> : ctx.t.dailyInterest}
    </MiniActionCard>

    {/* FIRST DEPOSIT */}
    {firstTime ? <MiniActionCard
      CardIcon={account.firstDepositAmount > 0 ? DiamondIcon : CreditCardRoundedIcon}
      cardLink='/vault/saving/firstDeposit'
      isActive={account.firstDepositAmount > 0}>
      {account.firstDepositAmount > 0 ? <>
        <Typography fontWeight={700}>{ctx.t.firstDeposit}</Typography>
        <Typography variant='primary'>{account.firstDepositAmount.toFixed(2) + ctx.user.currency.symbol}</Typography>
      </> : ctx.t.firstDeposit}
    </MiniActionCard> : null}

    {/* SAVING GOAL */}
    <MiniActionCard
      CardIcon={EmojiFlagsRoundedIcon}
      cardLink='/vault/saving/savingGoal'
      isActive={account.savingGoalActive || account.deadlineActive}>
      {account.savingGoalActive || account.deadlineActive ? <>
        <Typography fontWeight={700}>{account.savingGoalActive ? ctx.t.savingGoal : ctx.t.deadline}</Typography>
        <Typography variant='primary'>{account.savingGoalActive ? account.savingGoal.toFixed() + ctx.user.currency.symbol : ''}{account.deadLine ? (account.savingGoalActive ? ' - ' : '') + dateSlash(account.deadLine) : ''}</Typography>
      </> : ctx.t.savingGoal}
    </MiniActionCard>


    {/* RECURRING TRANSFER */}
    <MiniActionCard
      CardIcon={SyncAltRoundedIcon}
      cardLink='/vault/saving/recurringTransfer'
      isActive={account.recurringTransferActive}>
      {<>
        <Typography fontWeight={700}>{ctx.t.recurringTransfer}</Typography>
        {account.recurringTransferActive ? <Typography variant='primary'>{account.recurringTransferAmount.toFixed(2) + ctx.user.currency.symbol} {ctx.t.frequencyPeriod[account.recurringTransferFrequency].toLowerCase()}</Typography> : null}
      </>}
    </MiniActionCard>
  </div>
}

type MiniActionCardProps = { CardIcon: any, children: any, cardLink: string, isActive: boolean }
function MiniActionCard({ CardIcon, cardLink, children, isActive }: MiniActionCardProps) {
  const theme = useTheme()

  const iconSize = 30
  return <Paper sx={{ mb: 2 }}>
    <Link data-trk={`l-actionCardLink-to:${cardLink}`} to={cardLink} style={{ color: theme.palette.text.secondary }}>
      <Box p={2} sx={{ display: 'flex', alignItems: 'center' }} >
        <div style={{ flex: `0 0 ${iconSize}px` }}>
          {isActive ?
            <RoundIcon Icon={CardIcon} /> :
            <CardIcon sx={{ fontSize: iconSize }}
            />}
        </div>
        <Box sx={{ flex: '1 1 100%', fontWeight: 700, ml: 1 }}>{children}</Box>
        <div style={{ flex: `0 0 ${iconSize}px` }}>
          {isActive ?
            <PlayArrowRoundedIcon sx={{ fontSize: iconSize }} /> :
            <AddCircleOutlineRoundedIcon sx={{ fontSize: iconSize }} />
          }
        </div>
      </Box>
    </Link>
  </Paper>
}


export default VaultBuilderItems