

// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/line
import { linearGradientDef } from '@nivo/core'
import { ResponsiveLine } from '@nivo/line'
import themeVarz from './theme/theme-varz'


const defaultProps = {
    axisTop: null,
    axisRight: null,
    axisBottom: null,
    axisLeft: null,
    enableGridX: false,
    enableGridY: false,
    enablePoints: false,
    isInteractive: false,
}

type dataIds = 'a' | 'b' | 'c' | 'd'

export type DataCoord = {
    x: string | number | Date
    y: string | number | Date
}

export type NumberDataCoord = {
    x: number
    y: number
}

export type ChartData = {
    id: dataIds
    data: DataCoord[]
}

export type ChartDataNumber = {
    id: dataIds
    data: NumberDataCoord[]
}

type DefaultLineChartProps = {
    data: ChartData[]
}

export const DefaultLineChart = ({ data }: DefaultLineChartProps) => (
    <ResponsiveLine
        data={data}
        yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
        }}
        enableArea={true}
        fill={[
            { match: { id: 'a' }, id: 'gradientFill1' },
            { match: { id: 'b' }, id: 'gradientFill2' },
        ]}
        colors={themeVarz.paletteChart}
        defs={[
            // LINE GRADIENTS TODO NOT USED ANYMORE
            // linearGradientDef('gradientLineA', [
            //     { offset: 0, color: '#B72AD5' },
            //     { offset: 100, color: '#36ADEA' },
            // ], {
            //     gradientTransform: 'rotate(90 0.5 0.5)'
            // }),
            // linearGradientDef('gradientLineB', [
            //     { offset: 0, color: '#B72AD5' },
            //     { offset: 100, color: '#36ADEA' },
            // ], {
            //     gradientTransform: 'rotate(90 0.5 0.5)'
            // }),
            // FILL GRADIENT
            ...themeVarz.gradientChart.map((gradientValues, i) => linearGradientDef(`gradientFill${i + 1}`, gradientValues)),
        ]}
        {...defaultProps}
    />
)

