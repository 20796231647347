
import * as t from '../01_shared/types'
import parser from 'ua-parser-js'
import { EventTypes, Event } from './tracker-types'
import trackerConfig from './tracker-config'
import VERSION from 'src/VERSION'
import $ from 'core/femto'
import { ComponentConfigType } from './components-configs'


import { isset, generateObjectId, timeout } from 'topkat-utils'

const userAgent = parser(window.navigator.userAgent) // https://github.com/faisalman/ua-parser-js

let userId: string = ''
let lastScreenId: string = ''
let sessionStarted = false
let sessionId = generateObjectId()
let showLogs = false
let userEventCache: any[] = []

const tracker = {
  login(userId2: string, url: string, meta: t.ObjectGeneric = {}) {
    userId = userId2
    tracker.sendEvent('login', { url }, meta)
  },
  startSession(origin, buildConfig, gmt, meta = {}) {
    sessionStarted = true
    Object.assign(meta, {
      origin,
      buildConfig,
      gmt,
      device: {
        type: userAgent.device.type,
        hPx: window.screen.height,
        wPx: window.screen.width,
        pixelRatio: window.devicePixelRatio,
        brand: userAgent.device.vendor,
        model: userAgent.device.model,
        os: userAgent.os.name,
        osVersion: userAgent.os.version
      }
    })
    tracker.sendEvent('session-start', {}, meta)
  },
  sendEvent(type: EventTypes, componentConfig: Partial<ComponentConfigType>, meta: t.ObjectGeneric = {}, isPriorityEvent = false) {
    const event: Event = {
      type,
      screenId: componentConfig.url || window.location.pathname,
      addr: componentConfig.addr,
      meta: { ...meta, ...(componentConfig.params || {}) },
    }
    sendEvent(event, isPriorityEvent)
  },
  endSession() {
    tracker.sendEvent('session-end', { url: lastScreenId }, {}, true)
  },
  appGainFocus() {
    tracker.sendEvent('app-gain-focus', { url: lastScreenId })
  },
  appLoseFocus() {
    tracker.sendEvent('app-lose-focus', { url: lastScreenId }, {}, true)
  },
  showEventLog() {
    if (showLogs) {
      showLogs = false
      $('#event-data-container').css('display:none')
    } else {
      showLogs = true
      $('#event-data-container').css('display:block')
    }
  },
}

setTimeout(() => tracker.startSession('proto', { appVersion: VERSION.split('.') }, new Date().getTimezoneOffset()));

//----------------------------------------
// SEND EVENT
//----------------------------------------

function sendEvent(event: Event, emptyCacheIfPossible = false) {

  if (!sessionStarted) return console.error(`Please start a session before sending events!`, event)
  // compute screenId from componentId
  if (typeof event.addr !== 'undefined' && typeof event.screenId === 'undefined') event.screenId = event.addr.split('/')[0]
  // always record last screen
  if (typeof event.screenId !== 'undefined') lastScreenId = event.screenId

  userEventCache.push({ ...event, sessionId, ts: Date.now() })

  logEvent(event) // TODO make that not execute in production

  const emptyCache = isset(userId) && (emptyCacheIfPossible || userEventCache.length > trackerConfig.maxEventsInCacheBeforeForceSendingEventsViaApi)
  if (emptyCache) {
    sendEventApi()
  }
}

const inProgApiCalls = {}
async function sendEventApi(emergency = false) {
  const apiCallUniqueId = generateObjectId()
  let success = false
  let nbTries = 0
  inProgApiCalls[apiCallUniqueId] = [...userEventCache]
  userEventCache = []

  if (emergency) {
    // TODO navigator.sendBeacon(url, data)
  } else {
    while (!success) {
      try {
        nbTries++
        //    await $.api.evt.input(sessionId, inProgApiCalls[apiCallUniqueId])
        success = true
        delete inProgApiCalls[apiCallUniqueId]
      } catch (err) {
        console.error(err);
        await timeout(1000 * nbTries * nbTries) // retry after 1s, 2s, 4s, 8s, 16s...
      }
    }
  }
}

function logEvent(e: Event) {
  $('#event-data-container').prepend(`<span>${e.type} - ${['navigate-to', 'previous'].includes(e.type) ?
    e.meta?.screenId :
    typeof e.addr === 'string' ?
      e.addr : ''
    }${e.meta && Object.keys(e.meta).length ? ' == ' + JSON.stringify(e.meta) : ''}</span><br/>`)
}

export default tracker