import * as React from 'react'
import { appCtx } from '../01_shared/appContext'
import Page from '../01_shared/Page'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import TransferMoney from '../01_shared/TransferMoney'
import generalConfig from '../01_shared/general-config'
import FullScreenDialogInfo from 'src/01_shared/FullScreenDialogInfo'
import { ReactComponent as HorseIcon } from '../01_shared/assets/horse-icon.svg'
import RoundIcon from 'src/01_shared/RoundIcon'

const PremiumVaultTransferPage = () => {
  const ctx = React.useContext(appCtx)
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const onTransferSuccess = () => {
    ctx.editActiveAccount({ isPremium: true })
    setDialogOpen(true)
  }

  const userFiatAccount = ctx.getAccountByType('fiat')
  const userAktioAccount = ctx.getAccountByTypeIfExists('aktio') || ctx.createNewUserAccount('aktio')

  return <Page dataTrkName='premiumVaultTransfer' title={ctx.t.premiumVaultActivation}>
    <Box p={2}>
      <div style={{ textAlign: 'center' }}>
        <Typography variant='h1' >{ctx.t.almostThere}</Typography>
        <Typography sx={{ my: 3 }}>{ctx.t.premiumVaultTransferText}</Typography>
      </div>
      <TransferMoney
        lockTransferTarget
        lockTransferSource
        lockTargetAccountSelect
        sourceAccountId={userFiatAccount.id}
        targetAccountId={userAktioAccount.id}
        transferAmount={generalConfig.vaults.nbEuroOfAktioToStackForPremium}
        onTransferSuccess={onTransferSuccess}
      />
    </Box>
    <FullScreenDialogInfo timeout={999999999} isOpen={dialogOpen} setOpen={setDialogOpen} onClose={() => ctx.previous()} >
      <RoundIcon Icon={HorseIcon} iconSize={60} />
      <Typography sx={{ mt: 2, fontWeight: 700, textAlign: 'center', }}>{ctx.t.upgradeToPremiumVaultSuccess}</Typography>
      <Typography variant='primary'>{ctx.t.dailyInterestUpToBoosted}</Typography>
    </FullScreenDialogInfo>
  </Page>
}

export default PremiumVaultTransferPage