import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import ArrowRightRounded from '@mui/icons-material/ArrowRightRounded'
import { Link } from "react-router-dom";
import Paper from '@mui/material/Paper'

import { isset } from 'topkat-utils'

type ActionCardPropsType = {
  title: string
  leftIcon?: JSX.Element
  leftIconSize?: number
  leftIconBackgroundColor?: string
  subtitle?: string | JSX.Element
  description?: string | JSX.Element
  cardLink?: string
  'data-trk-name': string
  cardAction?: () => void
}
const ActionCard = ({ title, leftIcon, 'data-trk-name': dataTrk, leftIconSize = 40, leftIconBackgroundColor, subtitle, description, cardLink, cardAction }: ActionCardPropsType) => {
  const theme = useTheme()
  if (!isset(leftIconBackgroundColor)) leftIconBackgroundColor = theme.palette.primary.light

  return <Paper data-trk={'c-' + dataTrk} sx={{ mb: 2, p: 2 }}>
    <Link to={cardLink ? cardLink : '#'} data-trk={cardAction ? `a-${dataTrk}Action` : `l-${dataTrk}Link-to:${cardLink}`} onClick={cardAction}>
      <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
        {leftIcon ? <Box className='center' style={{ flex: `0 0 ${leftIconSize}px`, height: leftIconSize, width: leftIconSize, borderRadius: '50%', backgroundColor: leftIconBackgroundColor, marginRight: 12 }}>
          {leftIcon}
        </Box> : null}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography fontWeight={'bold'}>{title}</Typography>
          {subtitle}
        </div>
        <ArrowRightRounded sx={{ fontSize: 40, color: theme.palette.secondary.main }} />
      </div>
      {description ? <Typography fontSize={'small'} sx={{ mt: '12px' }}>{description}</Typography> : null}
    </Link>
  </Paper>
};

export default ActionCard