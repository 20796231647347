
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const Loader = (props) => {
    return (
        <Box data-trk='c-loader' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', left: 0, top: 0, position: 'absolute' }} {...props}>
            <CircularProgress />
        </Box>
    );
};

export default Loader;
