// import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'

type NewVaultDrawerProps = { open: boolean, setOpen: (open: boolean) => void, children: any }
const BottomDrawer = ({ open, setOpen, children }: NewVaultDrawerProps) => {
  const theme = useTheme()

  const swipeAreaWidth = 50

  const pullerStyle = {
    width: 70,
    height: 6,
    backgroundColor: theme.palette.bgDarkest.main,
    borderRadius: 3,
    top: 8,
    left: 'calc(50% - 35px)',
    position: 'relative',
    mt: 1,
    mb: 2,
  }

  return <SwipeableDrawer
    anchor="bottom"
    open={open}
    onClose={() => setOpen(false)}
    onOpen={() => setOpen(true)}
    swipeAreaWidth={swipeAreaWidth}
    disableSwipeToOpen={true}
    sx={{ '> .MuiPaper-root': { backgroundColor: 'transparent' } }}
  >
    <Paper sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
      <Box sx={pullerStyle}></Box>
      {children}
    </Paper>
  </SwipeableDrawer>
};

export default BottomDrawer