
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import { ReactComponent as HorseIcon } from '../01_shared/assets/horse-icon.svg'
import { ReactComponent as VaultIcon } from '../01_shared/assets/vault-icon.svg'
import * as t from './types'
import themeVarz from './theme/theme-varz'

const env = 'development'
const isDevEnv = !['uat', 'production'].includes(env);
const isProdEnv = !isDevEnv;
const nbDayPerMonthAverage = 365 / 12


const currency = {
  euro: ({
    id: 'euro',
    type: 'fiat',
    symbol: '€',
    short: `EUR`,
    long: `euro`,
  } as t.Currency),
  aktio: ({
    id: 'aktio',
    type: 'token',
    symbol: 'AKTIO',
    short: `AKTIO`,
    long: `aktio`,
  } as t.Currency),
  par: ({
    id: 'par',
    type: 'stableCoin',
    symbol: 'EUR PAR',
    short: `EUR PAR`,
    long: `EURO PAR`,
  } as t.Currency),
}

const accountType = {
  fiat: ({
    type: 'fiat',
    icon: EuroSymbolIcon,
    currency: currency.euro,
    color: '#ffffff',
    backgroundColor: '#003091',
    iconStyle: {
      '& svg': { width: '50%', height: '50%', left: '-3%', position: 'relative' },
    },
  } as t.AccountType),
  aktio: ({
    type: 'aktio',
    icon: HorseIcon,
    color: '#ffffff',
    backgroundColor: themeVarz.primaryBlue,
    currency: currency.aktio,
  } as t.AccountType),
  vault: ({
    type: 'vault',
    icon: VaultIcon,
    currency: currency.par,
    color: '#ffffff',
    backgroundColor: themeVarz.primaryBlue,
  } as t.AccountType),
}

const generalConfig = {
  env,
  isDevEnv,
  isProdEnv,
  serverUrl: isDevEnv ? 'http://localhost:9086/api/' : 'PRODURL',
  errDisplayTime: 2000,
  currency,
  accountType,
  nbDayPerMonthAverage,
  // currencySource
  //   currencyTarget
  exchangeRates: {
    euro: { // TODO this should be computed server side
      aktio: 0.5,
      euro: 1,
      par: 1,
    },
    aktio: {
      aktio: 1,
      euro: 2,
      par: 2,
    },
    par: {
      aktio: 2,
      euro: 1,
      par: 1,
    }
  },
  vaults: {
    percentSmart: 4,
    percentPremium: 10,
    nbEuroOfAktioToStackForPremium: 250,
    colors: ['#CD4141', '#EFA338', '#F8F944', '#53B330', '#46B9C8', '#945AD9', '#D95AAA'],
    backgroundColors: ['#F0ADAD', '#E6BC81', '#EDED9B', '#92DA77', '#B5D8DD', '#CFAAD9', '#D9AAC6'],
    firstTimeBuildVideoUrl: `/assets/video/video-9-16.mp4`,
    interestVideoUrl: `/assets/video/video-16-9.mp4`,
  },
  popularCrypto: [
    { fullName: `Bitcoin`, lastChange: 2.2 },
    { fullName: `Ethereum`, lastChange: 5.14 },
    { fullName: `TetherUS`, lastChange: -0.01 },
    { fullName: `BNB`, lastChange: -3.3 },
    { fullName: `USDC`, lastChange: -0.02 },
    { fullName: `Ripple`, lastChange: 2.99 },
    { fullName: `Terra`, lastChange: -0.18 },
    { fullName: `Solana`, lastChange: +7.15 },
  ],
  recurringTransferPeriods: {
    // THOSE CONFIG INDUCES FUNCTIONAL CODE
    monthly: nbDayPerMonthAverage,
    weekly: 7,
    yearly: 365,
  },
}

export default generalConfig