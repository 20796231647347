import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import { appCtx } from '../01_shared/appContext'
import { useParams } from 'react-router-dom'
import Page from '../01_shared/Page'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import VaultImage from './VaultImage'
import * as t from '../01_shared/types'
import { convertInUserCur } from '../01_shared/services/convert'
import Button from '@mui/material/Button'
import LinearProgress from '@mui/material/LinearProgress'
import SwipeableViews from 'react-swipeable-views'
import { Link } from "react-router-dom"
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import RoundIcon from '../01_shared/RoundIcon'
import { ReactComponent as HorseIcon } from '../01_shared/assets/horse-icon.svg'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'
import { DefaultLineChart, NumberDataCoord, ChartDataNumber } from 'src/01_shared/default-chart'
import generalConfig from '../01_shared/general-config'
import TransactionList from 'src/01_shared/TransactionList'
import VaultBuilderItems from './VaultBuilderItems'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import FinancialHeaderResume from 'src/01_shared/FinancialHeaderResume'
import themeVars from '../01_shared/theme/theme-varz'
import { ReactComponent as ParIcon } from '../01_shared/assets/par-icon.svg'
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded'

import { miniTemplater, differenceInDays, random, forI, JSONstringyParse, round2 } from 'topkat-utils'


// type DataCoord = { x: number, y: number }
// type ChartData = { id: 'a' | 'b' | 'c', data: DataCoord[] }

const dataPointsForInterpolation = 30
const genData = (nbDays, isPremium = false): NumberDataCoord[] => interpolateArray(forI(nbDays, i => i === 0 ? 0 : (i + Math.random() + Math.random()) * (isPremium ? 2 : 1)) as number[], dataPointsForInterpolation).map((n, i) => ({ x: i, y: n }))

const chartData: { [k: string]: ChartDataNumber[] } = {
  week: [{ id: 'a', data: genData(7), }],
  month: [{ id: 'a', data: genData(30), }],
  year: [{ id: 'a', data: genData(55), }],
  all: [{ id: 'a', data: genData(55), }],
}
const chartDataPremium: { [k: string]: ChartDataNumber[] } = {
  week: [{ id: 'a', data: genData(7, true) }, { id: 'b', data: genData(7), },],
  month: [{ id: 'a', data: genData(30, true) }, { id: 'b', data: genData(30), },],
  year: [{ id: 'a', data: genData(55, true) }, { id: 'b', data: genData(55), },],
  all: [{ id: 'a', data: genData(55, true) }, { id: 'b', data: genData(55), },],
}



const VaultMainPage = () => {
  const theme = useTheme()
  const ctx = React.useContext(appCtx)

  const tabIndexes = ['progress', 'transactions', 'builder']
  const tabDefault = tabIndexes.indexOf(window.location.hash.replace('#', ''))
  const [tabIndex, setTabIndexStt] = React.useState(tabDefault === -1 ? 0 : tabDefault);
  const [animateHeightBug, setAnimateHeightBug] = React.useState(true)
  const { vaultId } = useParams()
  const setTabIndex = (i) => {
    window.location.hash = tabIndexes[i]
    setTabIndexStt(i)
  }

  const vault = ctx.ensureActiveAccount(vaultId) // TODO fix bug on first renderning
  const vaultImgBoxStyle = { position: 'relative', borderBottomLeftRadius: 25, borderBottomRightRadius: 25, backgroundColor: theme.palette.bgDarker.main }
  const userFiatAccount = ctx.getAccountByType('fiat')

  const wealthHubConfigForDeposit = {
    sourceAccount: JSONstringyParse(userFiatAccount),
    targetAccount: JSONstringyParse(vault),
  } as Partial<t.TransferMoneyPropsType>
  const wealthHubConfigFroWithdrawal = {
    sourceAccount: JSONstringyParse(vault),
    targetAccount: JSONstringyParse(userFiatAccount),
  } as Partial<t.TransferMoneyPropsType>

  return <Page dataTrkName='vault' title={vault.name} previousUrl='/' titleAbsolute>

    <Box p={2} mb={2} sx={vaultImgBoxStyle}>
      <div className='center' style={{ height: 250 }}>
        <VaultImage account={vault} />
      </div>
      <div style={{ height: 80 }}></div> {/* SPACER */}
      <div style={{ position: 'absolute', bottom: 0 }}>
        <FinancialHeaderResume account={vault} />
        <Box mb={2} mt={2}>
          <Link data-trk='l-vaultDepositAmountBtn-to:/wealth-hub' to='/wealth-hub' state={wealthHubConfigForDeposit} >
            <Button variant='contained' sx={{ mr: 1 }}>{ctx.t.deposit} +</Button>
          </Link>
          <Link data-trk='l-vaultWithdrawAmountBtn-to:/wealth-hub' to='/wealth-hub' state={wealthHubConfigFroWithdrawal}>
            <Button variant='outlined'>{ctx.t.withdraw} -</Button>
          </Link>
        </Box>
      </div>
    </Box>
    {vault.type === 'vault' ? <>
      <Box p={2} style={{ position: 'sticky', zIndex: 9, top: 46, backgroundColor: `rgba(246, 248, 250, 0.9)` }}>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <TabBtn tabIndex={0} index={tabIndex} setIndex={setTabIndex}>{ctx.t.progress}</TabBtn>
          <TabBtn tabIndex={1} index={tabIndex} setIndex={setTabIndex}>{ctx.t.transactions}</TabBtn>
          <TabBtn tabIndex={2} index={tabIndex} setIndex={setTabIndex}>{ctx.t.builder}</TabBtn>
        </div>
      </Box>
      <SwipeableViews
        animateHeight={animateHeightBug}
        index={tabIndex}
        onChangeIndex={i => setTabIndex(i)}
      >
        {/* inner box because of viewport bug */}
        <Box p={2}><ProgressTab setAnimateHeightBug={setAnimateHeightBug} /></Box>
        <Box p={2}><TransactionsTab /></Box>
        <Box p={2}><BuilderTab /></Box>
      </SwipeableViews>

    </> : <Box p={2}>
      <TransactionsTab />
    </Box>
    }
  </Page>
}

export default VaultMainPage


function ProgressTab({ setAnimateHeightBug }) {
  // const theme = useTheme()
  const ctx = React.useContext(appCtx)

  const account = ctx.ensureActiveAccount()
  const lastEarningTransactions = ctx.user.transactions.filter(t => t.type === 'earn' && t.accountTarget === account.id)

  return <Box>
    {account.savingGoalActive || account.deadlineActive ? <GoalProgressSection /> : null}
    <EarningsSection setAnimateHeightBug={setAnimateHeightBug} />
    {lastEarningTransactions.length ? <LastDailyEarningsSection transactions={lastEarningTransactions} /> : null}
    <Typography variant='subtitle2' sx={{ p: 2 }}>* {ctx.t.yieldRateDisclaimer}</Typography>
  </Box>
}


function GoalProgressSection() {
  const theme = useTheme()
  const ctx = React.useContext(appCtx)

  const account = ctx.ensureActiveAccount()
  const daysLeft = Math.round(differenceInDays(new Date(), account.deadLine))
  const daysTotal = Math.round(differenceInDays(account.creationDate, account.deadLine))
  const goalProgressPercent = account.savingGoalActive ? (100 / account.savingGoal) * account.totalAmount : (100 / daysTotal) * (daysTotal - daysLeft)
  const progressRankPhrase = miniTemplater(ctx.t.progressRanks[Math.min(10, Math.floor(goalProgressPercent / 10))], { daysLeft })
  const dateIfDeadline = account.deadlineActive ? new Intl.DateTimeFormat(ctx.user.dateFormat, { dateStyle: 'medium' }).format(account.deadLine) : ''

  return <>
    <Typography variant='h2' sx={{ my: 2 }}>{ctx.t.goalProgress}</Typography>
    <Paper sx={{ p: 2 }}>
      <Box className='row' sx={{ mb: 2 }}>
        {account.savingGoalActive ? <>
          <Typography variant='h4'>{miniTemplater(ctx.t.savedAmount, { amount: `${goalProgressPercent.toFixed(0)}%` })}</Typography>
          <Typography variant='h4'>
            {account.currency.symbol} {account.totalAmount.toFixed(0)} / {account.savingGoal.toFixed(0)}
          </Typography>
        </> : <>
          <Typography variant='h4'>{ctx.t.deadline}</Typography>
          <Typography variant='h4'>{dateIfDeadline}</Typography>
        </>}
      </Box>
      <LinearProgress variant="determinate" value={goalProgressPercent} style={{ height: 10, borderRadius: 5, backgroundColor: theme.palette.bgDarkest.main }} />
      {account.deadlineActive ? <Box sx={{ mt: 2 }}>
        <Typography>
          {account.savingGoalActive ? dateIfDeadline + ' -' : ''} {progressRankPhrase}
        </Typography>
      </Box> : null}
    </Paper>
  </>
}


function EarningsSection({ setAnimateHeightBug }) {
  const theme = useTheme()
  const ctx = React.useContext(appCtx)

  const [expanded, setExpanded] = React.useState(false)

  const account = ctx.ensureActiveAccount()
  const iconSize = 30
  const expandIconSize = 30
  const [lastEarnTransaction] = ctx.getLastTransactions(1, t => t.type === 'earn' && t.accountTarget === account.id)
  const lastDayRevenue = convertInUserCur(ctx, lastEarnTransaction?.amountTarget || 0)
  const earningActivated = account.isSmart
  const isPremium = account.isPremium && account.isSmart
  const last24hEarnIfPremium = isPremium ? 0 : account.totalAmount * (generalConfig.vaults.percentPremium / 100) / 23

  return <>
    <Typography variant='h2' sx={{ my: 2 }}>{ctx.t.earnings}</Typography>
    <Paper className='expandable'>
      <Link data-trk='l-vaultDailyInterestCardLink-to:/vault/saving/dailyInterest' to='/vault/saving/dailyInterest' style={{ color: theme.palette.secondary.main }}>
        <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }} >
          <div style={{ flex: `0 0 ${iconSize}px` }}>
            <RoundIcon Icon={isPremium ? HorseIcon : earningActivated ? ParIcon : StarBorderRoundedIcon} />
          </div>
          <Box sx={{ flex: '1 1 100%', fontWeight: 700, ml: 1 }}>
            {isPremium ? <>
              <Typography variant='primary'>{ctx.t.premiumVaultActivated}</Typography>
              <Typography fontWeight={700}>{ctx.t.dailyInterestUpToBoosted}</Typography>
            </> : earningActivated ? <>
              <Typography variant='primary'>{ctx.t.smartVaultActivated}</Typography>
              <Typography fontWeight={700}>{ctx.t.dailyInterestUpTo}</Typography>
            </> : <>
              <Typography variant='primary'>{ctx.t.activateDailyEarnings}</Typography>
              <Typography fontWeight={700}>{ctx.t.earnUpToBoosted}</Typography>
            </>}
          </Box>
          <div style={{ flex: `0 0 ${iconSize}px` }}>
            <PlayArrowRoundedIcon sx={{ fontSize: 30 }} />
          </div>
        </Box>
      </Link>
      <Box sx={{ backgroundColor: theme.palette.bgDarker.main, p: 1, textAlign: 'center' }}>
        <Typography>{ctx.t.liveInterestRate} : {random(-3, 15, 2)}%*</Typography>
      </Box>
      <Box sx={{ textAlign: 'center', p: 2 }}>
        <Typography variant='h2'>{ctx.t.last24hEarnings}</Typography>
        <Typography fontSize={35} fontWeight={700}>
          {(earningActivated ? convertInUserCur(ctx, lastDayRevenue * (isPremium ? 2 : 1)) : 0).toFixed(2)}{ctx.user.currency.symbol}
        </Typography>
        {!isPremium ? <Typography variant='subtitle2'>{ctx.t.youCouldHaveEarnToday({
          amount: '<span class="text-primary">' + last24hEarnIfPremium.toFixed(2) + ctx.user.currency.symbol + '</span>',
        })}</Typography> : null}
      </Box>

      <FinanceChart display={expanded} />

      <Paper
        elevation={2}
        style={{ position: 'absolute', height: expandIconSize, width: expandIconSize, borderRadius: '50%', bottom: -expandIconSize / 2, marginLeft: '50%', left: -expandIconSize / 2 }}
        onClick={async () => {
          setExpanded(!expanded)
          setAnimateHeightBug(false) // forces refresh of swipable view shit lib
          setTimeout(() => {
            console.log(`BUGFREE`)
            setAnimateHeightBug(true)
          }, themeVars.transitionTime * 1000)
        }}
      >
        {expanded ? <ExpandLessRoundedIcon color='primary' fontSize='large' /> : <ExpandMoreRoundedIcon color='primary' fontSize='large' />}
      </Paper>
    </Paper>
  </>
}


function FinanceChart({ display = false }) {
  const ctx = React.useContext(appCtx)
  const account = ctx.ensureActiveAccount()
  const isPremium = account.isPremium && account.isSmart
  const lastPeriodGain = 31.26
  const lastPeriodGainPremium = lastPeriodGain * 2

  const [period, setPeriod] = React.useState('week')

  const data = isPremium ? chartData[period] : chartDataPremium[period]
  // [{ id: 'a', data: genData(7, true) }, { id: 'b', data: genData(7), },],
  const maxDataValue = Math.max(...data.map(dta => Math.max(...dta.data.map(xy => xy.y)))) // 22
  const lastDotHeightPerData = data.map(dta => dta.data[dta.data.length - 1].y) // [16,12,...]
  const heightRatio = 100 / maxDataValue
  const lastDotHeightPerDataPercent = lastDotHeightPerData.map(h => 100 - h * heightRatio)

  const periods = {
    week: 7,
    month: generalConfig.nbDayPerMonthAverage,
    year: 365,
    all: 9999,
  }

  return <Box p={2} style={{
    position: 'relative',
    transition: `max-height ${themeVars.transitionTime}s, opacity ${round2((themeVars.transitionTime / 4) * 3)}s ${round2((themeVars.transitionTime / 4) * 1)}s`,
    overflow: 'hidden',
    maxHeight: display ? 999 : 0,
    opacity: display ? 1 : 0,
  }} >
    <div style={{ position: 'relative', marginTop: 8 }}>
      <PriceBar
        color={themeVars.paletteChart[0]}
        text={`+${(isPremium ? lastPeriodGain : lastPeriodGainPremium).toFixed(2) + ctx.user.currency.symbol}`}
        subtitle={new Intl.DateTimeFormat(ctx.user.dateFormat).format(new Date())}
        heightPercent={lastDotHeightPerDataPercent[0]}
      />
      {!isPremium ? <PriceBar
        color={themeVars.paletteChart[1]}
        text={`+${lastPeriodGain.toFixed(2) + ctx.user.currency.symbol}`}
        heightPercent={lastDotHeightPerDataPercent[1]}
      /> : null}
      <div style={{ position: 'relative', height: 300 }}>
        <DefaultLineChart data={data} />
      </div>
    </div>
    <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: 8, marginBottom: 24 }}>
      {Object.keys(periods).map((periodName) => {
        return <Button data-trk={`a-vaultChartPeriodChangeBtn-value:${periodName}`} key={periodName} size="small" variant={period === periodName ? 'contained' : 'text'} onClick={() => setPeriod(periodName)}>
          {ctx.t[periodName]}
        </Button>
      })}
    </Box>
  </Box >
}

function PriceBar({ color, text, subtitle = '', heightPercent }) {
  const theme = useTheme()

  const top = `calc(${heightPercent}% - ${subtitle ? '24' : '16'}px)`

  const priceWrapperStyle: any = { display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', position: 'absolute', lineHeight: 1.5, top, zIndex: 1 }
  const priceStyle: any = { flex: '1 0 96px', color, padding: 4, fontWeight: 700, fontSize: 'small', textAlign: 'center' }
  const dashedLineStyle = { flex: '1 1 100%', borderBottom: `1px dashed ${theme.palette.secondary.main}` }
  const dotStyle = { flex: '0 0 12px', border: '2px solid white', backgroundColor: color, borderRadius: '50%', height: 12, transform: 'translate(5px)' }

  return <div style={priceWrapperStyle}>
    {/* PRICE BAR */}
    <Paper style={priceStyle}>
      {text}<br />
      {subtitle ? <Typography variant='subtitle2'>{subtitle}</Typography> : null}
    </Paper>
    <div style={dashedLineStyle}></div>
    <div style={dotStyle}></div>
  </div>
}

type LastDailyEarningsSectionProps = { transactions: t.AccountTransaction[] }
function LastDailyEarningsSection({ transactions }: LastDailyEarningsSectionProps) {
  const ctx = React.useContext(appCtx)

  return <Box >
    <Typography variant='h2' sx={{ my: 2 }}>{ctx.t.lastDailyEarnings}</Typography>
    <TransactionList transactions={transactions} />
  </Box>
}

function TransactionsTab() {
  const ctx = React.useContext(appCtx)

  const account = ctx.ensureActiveAccount()
  const transactionList = ctx.user.transactions.filter(t => t.accountTarget === account.id || t.accountSource === account.id)

  return <>
    <Box>
      <FormControl variant="outlined" fullWidth>
        <TextField
          fullWidth
          style={{ padding: 8, fontSize: 24 }}
          InputProps={{
            startAdornment: <InputAdornment position="start">
              <SearchRoundedIcon style={{ fontSize: 30, }} />
            </InputAdornment>,
          }}
          onChange={e => e.target.value}
        />
      </FormControl>
    </Box>
    <Box className='row' my={2} >
      <Typography variant='h2' >{ctx.t.transactionHistory}</Typography>
      <Typography variant='h2' color='primary'>{ctx.t.downloadAll}</Typography>
    </Box>
    {transactionList.length ?
      <TransactionList transactions={transactionList} maxN={99} /> :
      <Paper sx={{ p: 2 }}><Typography variant='subtitle2'>{ctx.t.noTransactionForTheMoment}</Typography></Paper>}
  </>
}

function BuilderTab() {
  const theme = useTheme()
  const ctx = React.useContext(appCtx)
  const account = ctx.ensureActiveAccount()

  return <Box>
    <VaultBuilderItems account={account} firstTime={false} />
    <Box mt={2} style={{ textAlign: 'center' }}>
      <Button variant='text' style={{ color: theme.palette.text.disabled }} >
        {ctx.t.closeMyAccount}
      </Button>
    </Box>
  </Box>
}



function TabBtn({ tabIndex, index, setIndex, children }) {
  const theme = useTheme()
  const isActive = index === tabIndex
  return <Button
    variant='text'
    style={{ backgroundColor: isActive ? theme.palette.bgDarker.main : 'transparent', color: theme.palette.secondary.main }}
    onClick={() => setIndex(tabIndex)}
  >
    {children}
  </Button>
}

//----------------------------------------
// FINANCIAL HEADER RESUME
//----------------------------------------

function interpolateArray(data, fitCount) {

  var linearInterpolate = function (before, after, atPoint) {
    return before + (after - before) * atPoint;
  };

  var newData: number[] = [];
  var springFactor = (data.length - 1) / (fitCount - 1);
  newData[0] = data[0]; // for new allocation
  for (var i = 1; i < fitCount - 1; i++) {
    var tmp = i * springFactor;
    var before = Math.floor(tmp)
    var after = Math.ceil(tmp)
    var atPoint = tmp - before
    newData[i] = linearInterpolate(data[before], data[after], atPoint);
  }
  newData[fitCount - 1] = data[data.length - 1]; // for new allocation
  return newData;
}